import { Link } from "@chakra-ui/react";
import NextLink from "next/link";
import React from "react";

type GetYourButtonProps = {
  variant?: string;
};

const GetYourQuoteButton = ({ variant }: GetYourButtonProps) => {
  const v = variant ? variant : "brand-solid-accent";
  const hiddenz = (
    <>
      <NextLink href="tel:18334633262" passHref legacyBehavior>
        <Link
          size="lg"
          mt={0}
          px={4}
          py={3}
          sx={{ fontSize: "0.9rem" }}
          variant={v}
        >
         Call Now
        </Link>
      </NextLink>
    </>
  );

  const el = process.env.NEXT_PUBLIC_GET_QUOTE_URL ? hiddenz : <span />;
  return el;
};

export default React.memo(GetYourQuoteButton);
