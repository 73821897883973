/* eslint-disable react/destructuring-assignment */
// @ts-nocheck
import { ExternalLinkIcon } from "@chakra-ui/icons";
import ReactDOMServer from "react-dom/server";

import {
  documentToReactComponents,
  Options,
} from "@contentful/rich-text-react-renderer";
import { BLOCKS, Document, INLINES } from "@contentful/rich-text-types";
import React from "react";
import BulletList from "src/components/atoms/BulletList";
import { BlockRenderer } from "src/components/molecules/BlockRenderer";
import { BackgroundColorType } from "src/services/contentful/twoStackColumn/twoStackColumnParser";
import OrderedList from "../OrderedList";
import { Excerpt } from "./styles";
import { documentToHtmlString } from "@contentful/rich-text-html-renderer";

const hostURL = process.env.NEXT_PUBLIC_SITE_URL || "";
const siteName = hostURL
  .replace("https://www", "")
  .replace("https://", "")
  .replace("/", "");

export interface TextBlockProps {
  header?: string;
  richContent: Document;
  backgroundColor?: BackgroundColorType;
  align: "left" | "center" | "right";
}

export const renderDocument = (document: Document, restProps: any) => {
  // eslint-disable-next-line
  const backgroundColor = restProps?.backgroundColor || "white";
  const headerColor = backgroundColor === "dark_blue" ? "white" : "dark_blue";
  const options: Options = {
    renderNode: {
      // eslint-disable-next-line react/display-name
      [BLOCKS.OL_LIST]: (node, children) =>
        children &&
        Array.isArray(children) && (
          <OrderedList headerColor={headerColor}>{children}</OrderedList>
        ),

      // eslint-disable-next-line react/display-name
      [BLOCKS.UL_LIST]: (node, children) =>
        children &&
        Array.isArray(children) && (
          <BulletList headerColor={headerColor}>{children}</BulletList>
        ),
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        const t =
          (node?.data?.uri as string).includes(siteName) ||
          !(node?.data?.uri as string).includes("http") ? (
            // eslint-disable-next-line
            <a href={node.data.uri}>{children}</a>
          ) : (
            // eslint-disable-next-line
            <a href={node.data.uri} target="_blank" rel="noopener noreferrer">
              {children} <ExternalLinkIcon sx={{ mt: "-5px" }} />
            </a>
          );

        // remove styles and svg from html string
        return t;
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        const t = (
          <a href={"/" + node?.data?.target?.fields?.slug}>{children}</a>
        );

        // remove styles and svg from html string
        return t;
      },
      [INLINES.ASSET_HYPERLINK]: (node) => renderAssetHyperlink(node),
      [INLINES.EMBEDDED_ENTRY]: (node, children) => {
        return (
          <BlockRenderer
            block={node?.data?.target}
            key={node?.data?.target?.sys?.id}
            layoutId={node?.data?.target?.sys?.id}
          />
        );
      },
    },
    renderText: (text) => {
      return text.split("\n").flatMap((line, i) => [i > 0 && <br />, line]);
    },
  };

  return documentToReactComponents(document, options);
};

export const renderDocumentToHtmlString = (
  document: Document,
  restProps: any
) => {
  // eslint-disable-next-line

  const options: Options = {
    renderNode: {

      // eslint-disable-next-line react/display-name
      [BLOCKS.OL_LIST]: (node, children) =>
        children &&
        Array.isArray(children) && <OrderedList>{children}</OrderedList>,

      // eslint-disable-next-line react/display-name
      [BLOCKS.UL_LIST]: (node, children) =>
        children &&
        Array.isArray(children) && (
          <BulletList>{node.content[0].value}</BulletList>
        ),
      // eslint-disable-next-line react/display-name
      [INLINES.HYPERLINK]: (node, children) => {
        let htmlString = (
          // eslint-disable-next-line
          <a href={node.data.uri}>{node.content[0].value}</a>
        );

        htmlString = ReactDOMServer.renderToStaticMarkup(htmlString);

        return htmlString;
      },
      [INLINES.ENTRY_HYPERLINK]: (node, children) => {
        let htmlString = (
          <a href={"/" + node?.data?.target?.fields?.slug}>
            {node.content[0].value}
          </a>
        );

        // remove styles and svg from html string
        htmlString = ReactDOMServer.renderToStaticMarkup(htmlString);

        return htmlString;
      },
      [INLINES.ASSET_HYPERLINK]: (node) => renderAssetHyperlink(node),
    },
    renderText: (text) => {
      return text.split("\n").flatMap((line, i) => [i > 0 && <br />, line]);
    },
  };

  return documentToHtmlString(document, options);
};

const TextBlock: React.FC<TextBlockProps> = ({
  header,
  richContent,
  ...rest
}) => (
  <>
    {/*header ? <Title {...rest}>{header}</Title> : null */}
    <Excerpt {...rest}>{renderDocument(richContent, rest)}</Excerpt>
  </>
);

const renderAssetHyperlink = (node) => {
  let htmlString = (
    <a href={node?.data?.target?.fields?.file?.url}>
      {node.content[0].value}
    </a>
  );

  // remove styles and svg from html string
  //htmlString = ReactDOMServer.renderToStaticMarkup(htmlString);

  return htmlString;
}

export default TextBlock;
