import React, { createContext, useState, ReactNode } from "react";
import filter from "lodash/filter";

// Define the type for the context value
export type ContentfulContextValue = {
  getExternalResource: (urn: string) => any;
  setExternalResource: (urn: string, data: any) => void;
};

interface ExternalResourceDataProps {
  urn: string;
  data: any;
}

// Create the context
const ContentfulContext = createContext<ContentfulContextValue | null>(null);

// Define the props type for the MyProvider component
interface MyProviderProps {
  children: ReactNode;
}

// Create the provider component
const ContentfulProvider: React.FC<MyProviderProps> = ({ children }) => {
  const [externalResourceData, setExternalResourceData] = useState<
    ExternalResourceDataProps[]
  >([]);

  // Define the updateData function
  const setExternalResource = (urn: string, data: any): void => {
    setExternalResourceData(data);
  };

  const getExternalResource = (urn: string): any => {
    return filter(externalResourceData, { urn: urn })[0];
  };

  // Create the context value object
  const contextValue: ContentfulContextValue = {
    getExternalResource,
    setExternalResource,
  };

  return (
    <ContentfulContext.Provider value={contextValue}>
      {children}
    </ContentfulContext.Provider>
  );
};

export { ContentfulContext, ContentfulProvider };
