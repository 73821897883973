import React, { Attributes, ReactNode } from "react";
import { BackgroundColorType } from "src/services/contentful/twoStackColumn/twoStackColumnParser";
import styled from "styled-components";

const DEFAULT_ICON = `fas fa-dot-circle`;

export interface ListProps extends Attributes {
  headerColor?: BackgroundColorType;
}

type Props = {
  children: Array<ReactNode>;
  headerColor?: BackgroundColorType;
  bulletIcon?: string;
};

const BulletListWrapper = styled.div`
  padding: 1.5rem 0 1.5rem 0;
`;

const Wrapper = styled.ul<ListProps>`
  display: inline-block;
  margin: 0 auto;
  padding: 0;
  list-style-type: none;

  > * {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding-left: 18px;
    margin-top: calc(5px / 2);
    padding-bottom: calc(5px / 2);

    i[aria-hidden] {
      margin-right: 10px;
      position: relative;
      top: 4px;
    }

    p {
      margin: 0;
    }

    span {
      text-align: left;
    }
  }
`;

const BulletList = ({ children, bulletIcon, ...rest }: Props) => {
  //console.log("children", children);

  //const child = children.props || children;

  let icon = bulletIcon || DEFAULT_ICON;

  return (
    <BulletListWrapper>
      <Wrapper {...rest}>
        {children && children.length > 0
          ? children.map((item: any, i: number) => {
              let itm = item;

              if (typeof item === "object" && item["type"] === "li") {
                const t = item.props.children;

                itm = typeof t === "object" ? t[0] : t;
              }

              return (
                <li key={i}>
                  <i aria-hidden="true" className={icon} />
                  {itm}
                </li>
              );
            })
          : children}
      </Wrapper>
    </BulletListWrapper>
  );
};

export default React.memo(BulletList);
