import styled from "styled-components";

interface LayoutProps {
  maxWidth?: number;
  fluid?: boolean;
}
export default styled.div<LayoutProps>`
  max-width: ${({ theme, maxWidth }) =>
    (maxWidth || theme.size.desktopMaxWidth) + theme.size.gutter * 2}px;
  padding: 0 ${({ theme }) => theme.size.gutter}px;
  width: 100%;
  margin: 0 auto;
  gap: 1em;
  display: flex;
  flex-direction: column;
`;
