import findIndex from "lodash/findIndex";
import React, { useContext, useState, useEffect } from "react";
import { ISourceTopicFields } from "src/types/generated/contentful";

export type SourceTopicContextType = {
  sources: ISourceTopicFields[];
  saveSource: (source: ISourceTopicFields) => void;
};

export const SourceTopicContext =
  React.createContext<SourceTopicContextType | null>(null);

const SourceTopicProvider = ({ children }: any) => {
  const [sources, setSources] = useState<ISourceTopicFields[]>([]);
  const saveSource = (source: any) => {
    // de dupe just in case of a re-render
    const i = findIndex(
      sources,
      (o: any) => o["data-layoutid"] === source["data-layoutid"]
    );

    if (i < 0) {
      sources.push(source);
    }

    const t = [...sources];

    setSources(t);
  };

  return (
    <SourceTopicContext.Provider value={{ sources, saveSource }}>
      {children}
    </SourceTopicContext.Provider>
  );
};

export default React.memo(SourceTopicProvider);
