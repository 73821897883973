import React from "react";
import { BlockRenderer } from "src/components/molecules/BlockRenderer";
import { getBackgroundColorBasedOnTheme } from "src/components/molecules/TextTopic/TextTopic";
import { down } from "styled-breakpoints";
import styled from "styled-components";
import { getThemeTexture } from "../Section/Section";

const getWidthFromThemeName = (themeName: string) => {
  const regex = /(w:\d+(px|rem|em|%|vw|vh)?)/g;

  const d = themeName.match(regex);
  if (d && d.length > 0) {
    return d[0].replace("w:", "");
  }
  return "100%";
};

export type FlexDisplayItemProps = {
  fw?: string;
  fh?: string;
  fv?: string;
  backgroundColor?: string;
  isFullWidth?: boolean;
  themeName?: string;
  textAlignment?: "Left" | "Center" | "Right" | undefined;
  direction?: "Row" | "Column" | undefined;
  style?: any;
};

const FlexDisplayItemWrapper = styled.div<FlexDisplayItemProps>`
  position: relative;
  flex: 1;
  display: flex;

  /* deprecated */
  ${(props: any) => `background-color: ${props.backgroundColor}` || ""};

  padding: ${(props: any) =>
    props.themeName && props.themeName.toLowerCase().indexOf("card") >= 0
      ? "3em"
      : "0"};

  ${down("md")} {
    margin: 0;

    padding: ${(props: any) =>
      props.themeName && props.themeName.toLowerCase().indexOf("card") >= 0
        ? "1.5em"
        : "0"};
  }

  ${(props: any) =>
    props.themeName
      ? `background-color: ${getBackgroundColorBasedOnTheme(props.themeName)}`
      : ""};

  ${(props: any) =>
    props.direction
      ? `gap: 2em; flex-direction: ${props.direction.toLowerCase()};`
      : "flex-direction: column;"};

  border-radius: ${(props: any) =>
    props.themeName && props.themeName.toLowerCase().indexOf("card") >= 0
      ? "1rem"
      : "0"};

  ${(props: any) =>
    props.borderRadius ? `border-radius: ${props.borderRadius};` : ""};

  ${(props: any) =>
    props.themeName && props.themeName.toLowerCase().indexOf("1/3") >= 0
      ? "width: 28%; min-width: 28%;"
      : ""};

  ${(props: any) =>
    props.themeName && props.themeName.toLowerCase().indexOf("1/2") >= 0
      ? "width: 47%; min-width: 47%;"
      : ""};

  ${(props: any) =>
    !props.isFullWidth && props.fw ? "width:" + props.fw + "%" : "width: 100%"};

  ${(props: any) =>
    props.themeName && getWidthFromThemeName(props.themeName)
      ? `max-width: ${getWidthFromThemeName(props.themeName)};`
      : ""};

  ${(props: any) =>
    props.themeName && props.themeName.toLowerCase().indexOf("shadow") >= 0
      ? `box-shadow: 0px 5px 10px 0px rgb(0 55 100 / 15%)`
      : ""};

  ${(props: any) =>
    props.fv
      ? "justify-content:" +
        props.fv
          .toLowerCase()
          .replace("middle", "center")
          .replace("top", "flex-start")
          .replace("bottom", "flex-end")
      : ""};
  ${(props: any) =>
    props.fh
      ? "align-items:" +
        props.fh
          .toLowerCase()
          .replace("middle", "center")
          .replace("left", "flex-start")
          .replace("right", "flex-end")
      : ""};

  p,
  b,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  i {
    ${(props: any) =>
      props.themeName && props.themeName.toLowerCase().indexOf("dark") >= 0
        ? `color: ${
            props.themeName.toLowerCase().indexOf("dark") >= 0
              ? props.theme.palette.common.white
              : props.theme.palette.common.darkBlue
          }`
        : ""};
    text-align: ${(props: any) =>
      props.textAlignment ? "" + props.textAlignment.toLowerCase() : "left"};
  }

  ${down("md")} {
    width: 100%;
    max-width: 100%;
    min-width: 100%;
    align-items: center;
    justify-content: center;
  }

  /*** contained theme - contains paragraph width ***/
  ${(props: any) =>
    props.themeName && props.themeName.toLowerCase().indexOf("contained") >= 0
      ? "padding: 0 10em;"
      : ""};

  ${down("lg")} {
    ${(props: any) =>
      props.themeName && props.themeName.toLowerCase().indexOf("contained") >= 0
        ? "padding: 0;"
        : ""};
  }

  /*** end contained theme ***/
`;

const FlexDisplayItem = (data: any) => {
  // is this component being directly called from contentful
  // or is it being called from a react component
  let inboundFromContentful = false;

  const d = Object.assign({}, data);

  if (data.flexDisplayHorizontalAlignment) {
    d.fh = data.flexDisplayHorizontalAlignment;
    inboundFromContentful = true;
  }

  if (data.flexDisplayVerticalAlignment) {
    d.fv = data.flexDisplayVerticalAlignment;
    inboundFromContentful = true;
  }

  const components = inboundFromContentful ? (
    <BlockRenderer block={d.content} layoutId={`${d["data-layoutid"]}-0`} />
  ) : (
    d.children
  );

  // if (d.themeName && d.themeName === "Dark Card with Short Texture 2") {
  //   console.log("FlexDisplayItem", JSON.stringify(d));
  // }

  return (
    <FlexDisplayItemWrapper
      {...d}
      style={data.style}
      data-layoutid={d.layoutId}
    >
      {getThemeTexture(d.themeName) || null}

      {data?.heading ? (
        <BlockRenderer
          layoutId={`${data["data-layoutid"]}-heading`}
          block={data?.heading}
        ></BlockRenderer>
      ) : null}
      {components}
    </FlexDisplayItemWrapper>
  );
};

export default React.memo(FlexDisplayItem);
