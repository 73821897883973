import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { Document } from "@contentful/rich-text-types";
import { ReactElement, useState, useEffect } from "react";
import { up } from "styled-breakpoints";
import styled, { useTheme } from "styled-components";
import { HiddenWrapper } from "../Paper";

export interface RichTextProps {
  header?: string;
  richContent: Document;
  alignment?: string;
  backgroundColor?: string;
  theme?: string;
}

//  background-color: ${(props) => props.backgroundColor};

type ComponentThemeProps = {
  textColor: string;
  backgroundColor: string;
  padding: string;
  fontSize: string;
  fontWeight: number;
};

export const Wrapper = styled.div<{ componentTheme: ComponentThemeProps }>`
  padding: 2rem 0.5rem;
  background-color: ${({ componentTheme }) => componentTheme.backgroundColor};

  ${up("md")} {
    padding: 1rem;
  }

  ${up("lg")} {
    padding: ${({ componentTheme }) => componentTheme.padding};
  }
`;

const Header = styled.h3<{
  componentTheme: ComponentThemeProps;
  alignment: string;
}>`
  text-align: ${(props) => props.alignment};
`;

const Text = styled.div<{
  alignment: string;
  componentTheme: ComponentThemeProps;
}>`
  text-align: ${(props) => props.alignment};
  color: ${({ componentTheme }) => componentTheme.textColor};
  font-size: ${({ componentTheme }) => componentTheme.fontSize};
  font-weight: ${({ componentTheme }) => componentTheme.fontWeight};
`;

//@deprecated
export default function RichText({
  header,
  richContent,
  alignment = "center",
  backgroundColor = "white",
  theme = "componentRichTextBaseLight",
}: RichTextProps): ReactElement {
  const [bgColor, setBgColor] = useState("white");
  const globalTheme = useTheme();
  const componentTheme: ComponentThemeProps = globalTheme.components[
    theme
  ] as ComponentThemeProps;

  useEffect(() => {
    if (backgroundColor === "dark_blue") {
      setBgColor("#003764");
    }
  }, [backgroundColor]);

  return (
    <>
      <Wrapper {...{ componentTheme, backgroundColor: bgColor }}>
        <Header {...{ componentTheme, alignment }}>{header}</Header>
        <Text {...{ componentTheme, alignment }}>
          {documentToReactComponents(richContent)}
        </Text>
      </Wrapper>
    </>
  );
}
