import React from "react";
import {
  ChakraProvider,
  Button,
  Flex,
  Box,
  IconButton,
  LinkOverlay,
} from "@chakra-ui/react";
import { HamburgerIcon } from "@chakra-ui/icons";

import { Navigation } from "./styles";
import MainMenuList from "../MainMenuList";

const HorizontalMenu = ({
  wrapperRef,
  isMobile,
  setDrawerOpen,

  data,
  toggleSubMenu,
  a11yClick,
  drawerOpen,
  activeMenus,
}: {
  wrapperRef: any;
  isMobile: boolean;
  setDrawerOpen: any;

  data: any;
  toggleSubMenu: any;
  a11yClick: any;
  activeMenus: any;
  drawerOpen: boolean;
}) => {
  return (
    <Navigation role="navigation" ref={wrapperRef} aria-label="Main Navigation">
      {/*<MainNavItemLink
          href="#"
          aria-label="menu"
          onClick={() => setDrawerOpen(true)}
        >
          MENU
      </MainNavItemLink>*/}
      <Box as="span" display={["inline", "inline", "none"]}>
        <Button
          variant="menu-bar-button"
          leftIcon={<HamburgerIcon />}
          onClick={() => setDrawerOpen(true)}
        >
          Menu
        </Button>
      </Box>
      <Box as="span" display={["none", "none", "block"]}>
        <MainMenuList
          {...{
            data,
            toggleSubMenu,
            a11yClick,
            activeMenus,
            setDrawerOpen,
            drawerOpen,
            isMobile,
          }}
        />
      </Box>
      {/**  <Menu id="menubar-main" role="menubar" aria-label="Main Menu">
                {process.env.NEXT_PUBLIC_MAIN_SITE_URL && (
                  <MenuItem href={process.env.NEXT_PUBLIC_MAIN_SITE_URL}>
                    Back To Main Site
                  </MenuItem>
                )}
                <MenuItem href="/">Blog Home</MenuItem>
                {process.env.NEXT_PUBLIC_ABOUT_US_URL && (
                  <MenuItem href={process.env.NEXT_PUBLIC_ABOUT_US_URL}>
                    About Us
                  </MenuItem>
                )}
              </Menu>
              */}
    </Navigation>
  );
};

export default React.memo(HorizontalMenu);
