import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {
  MegaPanelStyleOnly,
  NavItem,
  NavItemLink,
  SubMenuSection,
  SubMenuHeading,
  MegaList,
} from "./styles";

const MegaPanel = ({
  ariaPanelId,
  i,
  toggleSubMenu,
  a11yClick,
  item,
  activeMenus,
}: {
  ariaPanelId: string;
  i: number;
  toggleSubMenu: any;
  a11yClick: any;
  item: any;
  activeMenus: any;
}) => {
  return (
    <MegaPanelStyleOnly
      role="menu"
      isActive={activeMenus.includes(`menu-Mega-Menu-${i}`)}
      id={ariaPanelId}
      key={ariaPanelId}
    >
      {item.menuItems?.map((subMenuItem: any) => {
        const tree = subMenuItem?.menuItems?.map((subItem: any, j: number) => {
          return (
            <NavItem
              role="menuitem"
              aria-label="menu-item"
              id={`nav-Mega-Menu-Item-${j}`}
              key={j}
            >
              <NavItemLink
                id={`nav-Mega-Menu-Item-Link-${j}`}
                href={`${subItem.linkUrl}`}
                onClick={(e) =>
                  toggleSubMenu(e, "menu-Mega-Menu", subItem.linkUrl)
                }
                onKeyDown={(e) =>
                  a11yClick(e) &&
                  toggleSubMenu(e, "menu-Mega-Menu", subItem.linkUrl)
                }
              >
                {subItem.title}
              </NavItemLink>
            </NavItem>
          );
        });
        return (
          <>
            <SubMenuSection>
              <SubMenuHeading>{subMenuItem.title}</SubMenuHeading>
              <MegaList>{tree}</MegaList>
            </SubMenuSection>
          </>
        );
      })}
    </MegaPanelStyleOnly>
  );
};

export default React.memo(MegaPanel);
