import styled, { keyframes } from "styled-components";

export const MainList = styled.ul`
  flex-direction: row;
  position: absolute;
  display: flex;
  top: 0;
  left: 0;
  justify-content: flex-start;
  align-content: center;
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

export const MainNavItem = styled.li<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  height: auto;
  list-style: none;
  padding: 1em;
  justify-content: center;
  width: fit-content;
  ${(props) => {
    if (props.isActive) {
      return `

      background: ${props.theme.palette.primary.menuLight};


    `;
    }
    return ``;
  }}
`;

const menuCaretAnimation = keyframes`
up {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(180deg);
  }
}
`;

export const MainNavItemLink = styled.a<{ isActive?: boolean }>`
  color: white;
  text-decoration: none;
  font-size: 1rem;
  font-weight: 600;
  transition-duration: 0.8s;
  transition-property: transform;

  i {
    margin-left: 0.25rem;
    opacity: 0.6;

    &.up {
      transform: rotate(180deg);
    }
  }
  &:hover {
    text-decoration: none;
  }

  ${(props) => {
    if (props.isActive) {
      return `
      color: #000;
      background: white;
      padding: 1em;
      border-radius: 0.5rem 0.5rem 0 0;

    `;
    }
    return ``;
  }}
`;
