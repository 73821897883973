import { IHeadingFields } from "@src/types/generated/contentful";
import React from "react";
import Layout from "src/components/Layout";
import { BlockRenderer } from "src/components/molecules/BlockRenderer";
import { down } from "styled-breakpoints";
import styled, { DefaultTheme, StyledComponent } from "styled-components";
//import { motion, useScroll } from "framer-motion";

const SectionWrapperLight = styled.section`
  padding: 3em;
  overflow: hidden;
  background-color: #fff;
  text-align: center;
  position: relative;

  ${down("md")} {
    padding: 8%;
  }
`;

export enum HeadingFont {
  H1 = "h1",
  H2 = "h2",
  H3 = "h3",
  H4 = "h4",
  H5 = "h5",
  H6 = "h6",
}

type ThemeType = "light" | "dark" | "medium";

type HeadingProps = {
  headingFont: HeadingFont;
  theme: DefaultTheme;
  weight: number;
  themeType: ThemeType;
};

export const getHeadingFont = ({
  headingFont,
  weight,
  theme,
  themeType,
}: HeadingProps): string => {
  return `${headingFont.toString()}: {
    fontFamily: ${theme.typography.fontFamilies.secondary}
    fontSize: ${(theme.typography.p.fontSize || 1) * 3};
    fontWeight: ${weight};},
    color:
    ${down("sm")}: {
      fontSize: ${(theme.typography.p.fontSize || 1) * 2};
    }
  }`;
};
/*

  h2: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 2,
    fontWeight: 800,
    lineHeight: baseFontSize * 2,
    letterSpacing: 0.01,
    mobile: {
      fontSize: baseFontSize * 1.5,
      lineHeight: baseFontSize * 1.5,
    },
  },
  h3: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.8,
    fontWeight: 800,
    lineHeight: baseFontSize * 1.8,
    letterSpacing: 0.01,
    mobile: {
      fontSize: baseFontSize * 1.4,
      lineHeight: baseFontSize * 1.4,
      letterSpacing: 0.01,
    },
  },
  h4: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.3,
    fontWeight: 700,
    lineHeight: baseFontSize * 1.3,
    letterSpacing: 0.01,
    mobile: {
      fontSize: baseFontSize * 1.4,
      lineHeight: 1,
      letterSpacing: 0,
    },
  },
  h5: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.4,
    fontWeight: 700,
    lineHeight: baseFontSize * 1.4,
    mobile: {
      fontSize: baseFontSize * 1.2,
    },
  },
  h6: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.25,
    fontWeight: 600,
    lineHeight: baseFontSize * 1.25,
    mobile: {
      fontSize: baseFontSize * 1.2,
      fontWeight: 600,
      lineHeight: 1.2,
    },
  },
*/

const SectionWrapperMedium = styled(SectionWrapperLight)`
  background-color: ${(props) => props.theme.palette.common.lightBlue};
`;

const SectionWrapperDark = styled(SectionWrapperLight)`
  background-color: ${(props) => props.theme.palette.common.darkBlue};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  p,
  i,
  ol,
  li {
    color: ${(props) => props.theme.palette.common.white};
  }

  a:not([data-chakra-link], [data-full-icon]) {
    color: ${(props) => props.theme.palette.primary.accessibleLightPrimary};
  }
`;

const FooterWrapper = styled(SectionWrapperDark)`
  padding: 3em;

  h3 {
    color: ${(props) =>
      props.theme.palette.primary.accessibleLightPrimary} !important;
    font-weight: 600;
    font-size: 1.2rem;
    text-align: left;
    line-height: 1.5;
    padding: 0;
    margin: 0;
    margin-bottom: 1rem;
  }
  ${down("sm")} {
    h3 {
      margin-top: 3em;
    }
  }

  p,
  b,
  a {
    color: ${(props) => props.theme.palette.common.white} !important;
    font-size: 0.8rem;
    flex: unset;
  }

  p {
    margin: 0 0 10px 0;
  }
  a {
    font-weight: 700;
  }

  b {
    font-weight: 700;
  }

  hr {
    margin-top: 3em;
    height: 1px;
    border: none;
    border-top: 1px solid ${(props) => props.theme.palette.primary.main};
  }

  div[data-layoutid^="shd-main-footer-0-"] {
    text-align: left;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
    margin: 0;
    gap: 1em;
  }

  div[data-layoutid="shd-main-footer-0-1"] {
    width: 20% !important;
    min-width: 20% !important;
  }

  // fix images on 4th column
  div[data-layoutid="shd-main-footer-0-2"] div {
    display: block !important;
    flex: unset !important;
    height: unset !important;
    padding: 0 !important;
    margin: 0 !important;
    text-align: left;
  }

  // fix spacing for social icons
  div[title="Social Icons"] {
    a {
      margin: 0 0.5rem 0 0;
      width: 1.5rem !important;
      height: 1.5rem !important;
    }
  }

  div[data-layoutid^="shd-main-footer-0"] {
    flex-wrap: nowrap;
  }

  ${down("md")} {
    div[data-layoutid="shd-main-footer-0"] {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      > div {
        width: 45% !important;
        max-width: 45% !important;
        min-width: 45% !important;
        padding-top: 0;
      }
    }
  }

  ${down("sm")} {
    div[data-layoutid="shd-main-footer-0"] {
      display: flex;
      flex: 1;
      flex-wrap: wrap;
      > div {
        width: 100% !important;
        max-width: 100% !important;
        min-width: 100% !important;
        padding-top: 0;
      }
    }
  }
`;

/*
 ${(props) =>
    getHeadingFont({
      headingFont: HeadingFont.H2,
      weight: 800,
      theme: props.theme,
      themeType: "dark",
    })}
    */

const getThemeWrapper = (
  theme?: string
): StyledComponent<"section", DefaultTheme, {}, never> => {
  if (theme && theme.toLowerCase().indexOf("dark") >= 0) {
    return SectionWrapperDark;
  } else if (theme && theme.toLowerCase().indexOf("medium") >= 0) {
    return SectionWrapperMedium;
  } else if (theme && theme.toLowerCase().indexOf("footer") >= 0) {
    return FooterWrapper;
  } else {
    return SectionWrapperLight;
  }
};

const LargeTexture = styled.div<{ opacity?: number }>`
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100%;
  opacity: ${(props) => props.opacity || 1};
`;
const LargeTextureLight01 = styled(LargeTexture)`
  background-image: url(/backgrounds/large-background-light-01.svg);
`;
const LargeTextureLight02 = styled(LargeTexture)`
  background-image: url(/backgrounds/large-background-light-02.svg);
`;
const LargeTextureLight03 = styled(LargeTexture)`
  background-image: url(/backgrounds/large-background-light-03.svg);
`;

const LargeWaveTexture = styled(LargeTexture)`
  background-image: url(/backgrounds/wave-background.svg);
  background-position: center bottom;
  background-size: cover;
`;

const ShortTextureDark01 = styled(LargeTexture)`
  background-image: url(/backgrounds/short_dark_01.svg);
`;
const ShortTextureDark02 = styled(LargeTexture)`
  background-image: url(/backgrounds/short_dark_02.svg);
`;
const ShortTextureDark03 = styled(LargeTexture)`
  background-image: url(/backgrounds/short_dark_03.svg);
`;

export const getTexture = (themeName?: string): React.ReactElement | null => {
  //console.log("getTexture", themeName);
  switch (themeName) {
    case "Light with Texture":
      return <LargeTextureLight01 />;
    case "Light with Texture 2":
      return <LargeTextureLight02 />;
    case "Light with Texture 3":
      return <LargeTextureLight03 />;
    case "Medium with Texture":
      return <LargeTextureLight01 opacity={0.5} />;
    case "Medium with Texture 2":
      return <LargeTextureLight02 opacity={0.5} />;
    case "Medium with Texture 3":
      return <LargeTextureLight03 opacity={0.5} />;
    case "Dark with Texture":
      return <LargeTextureLight01 opacity={0.1} />;
    case "Dark with Texture 2":
      return <LargeTextureLight02 opacity={0.1} />;
    case "Dark with Texture 3":
      return <LargeTextureLight03 opacity={0.1} />;
    case "Dark with Wave Texture":
      return <LargeWaveTexture />;
    case "Dark Card with Short Texture":
      return <ShortTextureDark01 />;
    case "Dark Card with Short Texture 2":
      return <ShortTextureDark02 opacity={0.8} />;
    case "Dark Card with Short Texture 3":
      return <ShortTextureDark03 />;
    default:
      return null;
  }
};

export const TextureWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

const TextureWrapper2 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`;
const TextureWrapper3 = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 0;
`;

const Header = styled.h2`
  position: relative;
  margin: 0;
`;

const getOpacity = (theme?: string): number => {
  if (theme && theme.toLowerCase().indexOf("light") >= 0) {
    return 1;
  }

  if (
    theme &&
    theme.toLowerCase().indexOf("dark") >= 0 &&
    theme.toLowerCase().indexOf("short") >= 0
  ) {
    return 1;
  }

  if (theme && theme.toLowerCase().indexOf("dark") >= 0) {
    return 0.1;
  }

  if (theme && theme.toLowerCase().indexOf("medium") >= 0) {
    return 0.5;
  }
  return 0;
};

const SectionContent = styled.div`
  position: relative;
`;

export const getThemeTexture = (
  themeName?: string
): React.ReactElement | undefined => {
  if (themeName && getTexture(themeName)) {
    return <TextureWrapper>{getTexture(themeName)}</TextureWrapper>;
  }

  return undefined;
};

export type SectionProps = {
  title?: string;
  technicalId?: string;
  theme?: string;
  children?: React.ReactNode;
  heading?: IHeadingFields;
  content?: any;
};

const Section: React.FC<SectionProps> = (data) => {
  const { title, technicalId, children } = data;

  const themeName = data.theme;

  const SectionWrapper = getThemeWrapper(themeName);

  return (
    <SectionWrapper id={technicalId}>
      <Layout>
        {getThemeTexture(themeName) || null}
        {/* <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          whileInView={{ opacity: 1, scale: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.4, delay: 0.3, ease: "easeOut" }}
        > */}
        {data?.heading ? (
          <BlockRenderer
            layoutId={`${technicalId}-heading`}
            block={data?.heading}
          ></BlockRenderer>
        ) : null}
        <SectionContent>
          {children ? (
            children
          ) : (
            <BlockRenderer
              layoutId={`${technicalId}`}
              block={data.content}
            ></BlockRenderer>
          )}
        </SectionContent>
        {/* </motion.div> */}
      </Layout>
    </SectionWrapper>
  );
};

export default Section;
