import React from "react";

import {
  Drawer,
  DrawerOverlay,
  DrawerContent,
  Accordion,
  AccordionItem,
  AccordionPanel,
  AccordionButton,
  AccordionIcon,
  Flex,
  IconButton,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import { ArrowForwardIcon, CloseIcon } from "@chakra-ui/icons";
import Link from "next/link";

import MegaPanel from "../MegaPanel";
import { montserrat } from "src/styles/brands/shd/foundations/typography";

const ScrollableContent = styled.div`
  overflow-y: scroll;
`;

const DrawerMenu = ({
  setDrawerOpen,
  drawerOpen,
  resetMenus,
  data,
  activeMenus,
  toggleSubMenu,
  a11yClick,
  handleDrawerVisibleChange,
}: {
  setDrawerOpen: any;
  resetMenus: any;
  drawerOpen: boolean;
  data: any;
  activeMenus: any;
  toggleSubMenu: any;
  a11yClick: any;
  handleDrawerVisibleChange: any;
}) => {
  return (
    <Drawer
      isOpen={drawerOpen}
      placement="right"
      onClose={() => {
        setDrawerOpen(false);
        resetMenus();
      }}
      size="full"
      //finalFocusRef={btnRef}
    >
      {/*<Drawer
      open={drawerOpen}
      onClose={() => {
        setDrawerOpen(false);
        resetMenus();
      }}
      handler={false}
      level={null}
      placement="right"
      onChange={handleDrawerVisibleChange}
    >*/}
      <DrawerOverlay />
      <DrawerContent>
        <Flex
          height={50}
          justifyContent="flex-end"
          alignItems="center"
          background="brandPrimary.500"
        >
          <IconButton
            onClick={() => setDrawerOpen(false)}
            variant="ghost"
            color="white"
            aria-label="Close Menu"
            style={{ padding: "0.5rem" }}
            _hover={{ background: "rgba(100,100,100,0.5)" }}
            _active={{ background: "rgba(100,100,100,0.9)" }}
            icon={<CloseIcon />}
          />
        </Flex>
        <ScrollableContent>
          {/* <MobileMenu>
          {process.env.NEXT_PUBLIC_MAIN_SITE_URL && (
            <MobileMenuItem>
              <MobileMenuLink href={process.env.NEXT_PUBLIC_MAIN_SITE_URL}>
                Back To Main Site
              </MobileMenuLink>
            </MobileMenuItem>
          )}
          <MobileMenuItem>
            <MobileMenuLink href="/" onClick={() => setDrawerOpen(false)}>
              Blog Home
            </MobileMenuLink>
          </MobileMenuItem>
          {process.env.NEXT_PUBLIC_ABOUT_US_URL && (
            <MobileMenuItem>
              <MobileMenuLink href={process.env.NEXT_PUBLIC_ABOUT_US_URL}>
                About Us
              </MobileMenuLink>
            </MobileMenuItem>
          )}
        </MobileMenu>*/}
          <Accordion allowToggle>
            {data?.menuItems?.map((item: any, i: number) => {
              const hasMoreItems = item.menuItems?.length > 0;

              return (
                <AccordionItem key={i}>
                  <AccordionButton
                    color={"brandAccent.500"}
                    aria-label={item.title}
                    onClick={
                      !hasMoreItems
                        ? () => {
                            window.location = item.linkUrl;
                          }
                        : undefined
                    }
                    style={{
                      cursor: "pointer",
                      fontWeight: 700,
                      padding: "1em",
                      border: "none",
                      fontSize: "1.2em",
                      fontFamily: montserrat.style.fontFamily,
                      borderBottom: "1px solid #ccc",
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    {hasMoreItems ? (
                      <>
                        {item.title}
                        <AccordionIcon />
                      </>
                    ) : (
                      <>
                        {item.title}
                        <ArrowForwardIcon />
                      </>
                    )}
                  </AccordionButton>

                  {item.menuItems?.length > 0 ? (
                    <AccordionPanel>
                      <MegaPanel
                        {...{
                          i,
                          ariaPanelId: `menu-Mega-Menu-Panel-${i}`,
                          toggleSubMenu,
                          a11yClick,
                          item,
                          activeMenus,
                          setDrawerOpen,
                          drawerOpen,
                        }}
                      />
                    </AccordionPanel>
                  ) : null}
                </AccordionItem>
              );
            })}
          </Accordion>
        </ScrollableContent>
      </DrawerContent>
    </Drawer>
  );
};

export default DrawerMenu;
