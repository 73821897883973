import styled, { keyframes } from "styled-components";

export const Navigation = styled.nav`
  position: relative;

  width: 100%;
  height: 50px;
  display: flex;
  flex-direction: column;
  border-top: 0.0625rem solid grey;
  padding: 0;
  border-right: 0.25rem solid #000;
  overflow-y: scroll;
  align-items: center;
  left: 0;
  border-top: none;
  flex-direction: row;
  border-right: none;
  border-bottom: 0.0625rem solid #000;
  overflow-y: visible;
  overflow-y: initial;
`;
