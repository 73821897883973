import styled, { keyframes } from "styled-components";
import { up, down } from "styled-breakpoints";

import Typography from "src/components/atoms/Typography";
import Link from "src/components/atoms/Link";
import Button from "src/components/atoms/Button/Button";
import Layout from "src/components/Layout";
import IconButton from "src/components/atoms/IconButton";
import { getTheme } from "src/styles/theme";
import BBBRating from "./BBB-rating";

export const SpeakText = styled.div`
  text-align: right;
  font-size: 0.9em;
  line-height: 1.1em;
  padding-right: 1rem;
  width: 200px;
  font-family: "Montserrat", sans-serif;
  font-weight: 700;

  ${down("lg")} {
    display: none;
  }

  ${down("sm")} {
    display: block;
    border: none;
    font-size: 0.8em;
    padding: 0;
    width: initial;
  }
`;

export const LineSeparator = styled.div`
  border-left: 1px solid #d7caca;
`;

export const PhoneNumber = styled.a`
  margin-left: 0.7rem;

  top: 3px;
  position: relative;
  font-size: 1.6em;
  font-family: "Montserrat", sans-serif;
  font-weight: 500;
  line-height: 1.1em;
  color: inherit;
  text-decoration: none;

  ${down("sm")} {
    display: none;
  }
`;

export const BBBRatingImage2 = styled(BBBRating)`
  width: 143px;
  margin-left: 1em;
  position: relative;

  ${down("sm")} {
    display: none;
  }
`;

const Logo = getTheme().media.logo;

export const Wrapper = styled.header`
  display: flex;
  flex-direction: column;
  position: relative;
  box-shadow: 0 2px 8px 0 rgba(0, 41, 85, 0.14);
  z-index: 1;

  ${down("md")} {
    flex-direction: column-reverse;
  }
`;

export const TopBar = styled.div`
  padding: 0.5em 0;
  background: ${({ theme }) => theme.palette.common.darkBlue};

  ${down("sm")} {
    padding: 1em;
  }
`;

export const TopBarText = styled(Typography).attrs({ variant: "h6" })`
  text-align: right;
  font-size: 21.6px;
  font-weight: 700;
  line-height: 1.25em;
  color: ${({ theme }) => theme.palette.common.white};

  ${up("lg")} {
    br {
      display: none;
    }
  }

  ${down("md")} {
    font-size: 19.2px;
    line-height: 1.3em;
    letter-spacing: -0.2px;
  }

  ${down("sm")} {
    font-size: 16px;
    line-height: 1.2em;
    text-align: center;
  }

  a {
    color: inherit;
  }
`;

export const TopBarSubText = styled.div`
  font-size: 1rem;
  font-weight: 600;
  line-height: 1.6em;
`;

export const NavigationWrapper = styled.div`
  background: ${({ theme }) => theme.palette.common.white};
`;

export const MenuNavWrapper = styled.div`
  background-color: ${({ theme }) => theme.palette.primary.menuDark};
`;

export const Inner = styled.div`
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1em;

  ${down("md")} {
    height: 90px;
  }
`;

export const StyledLogo = styled(Logo)`
  display: block;
  height: 60px;
  width: 100%;

  ${up("lg")} {
    height: 80px;
  }
`;

export const Menu = styled.ul`
  margin-left: auto;
  display: flex;

  ${down("md")} {
    display: none;
  }
`;

export const MenuItem = styled(Link)`
  font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
  font-size: 17.6px;
  font-weight: 600;
  line-height: 1em;
  text-transform: capitalize;
  text-decoration: none;
  letter-spacing: 0.2px;
  color: ${({ theme }) => theme.palette.text.heading};
  padding: 10px;
  white-space: nowrap;

  &:hover,
  &:focus {
    color: ${({ theme }) => theme.palette.primary.light};
  }
`;

export const HamburgerIconButton = styled(IconButton)`
  color: ${({ theme }) => theme.palette.primary.main};
  font-size: 30px;
  display: none;

  ${down("md")} {
    display: block;
  }
`;

export const MobileMenu = styled.div`
  padding-top: 30px;
`;

export const MobileMenuItem = styled.div`
  padding: 15px 0px 5px 0px;

  &:not(:last-child) {
    border-bottom: 1px solid #d4ebf44a;
  }
`;

export const MobileMenuLink = styled(Link)`
  font-family: ${({ theme }) => theme.typography.fontFamilies.secondary};
  font-size: 19.2px;
  font-weight: 600;
  line-height: 1em;
  text-transform: capitalize;
  text-decoration: none;
  letter-spacing: -0.5px;
  color: ${({ theme }) => theme.palette.common.white};
  white-space: nowrap;

  &:hover,
  &:focus {
    opacity: 0.7;
  }
`;
