import styled from 'styled-components'

export default styled.ol`
  margin: 0;
  padding-left: 20px;
  > * {
    padding-left: 10px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }
`
