/* eslint-disable @next/next/no-html-link-for-pages */
import { ReactElement } from "react";
import MainHeader from "src/components/organisms/MainHeader";
import { FooterParsed } from "src/services/contentful/footer/footerParser";
import { IHeaderFields } from "src/types/generated/contentful";
import styled from "styled-components";
import { BlockRenderer } from "./molecules/BlockRenderer";
import { WebSettingsFragment } from "@src/lib/__generated/graphql.types";

const Main = styled.main`
  background: white;
  position: relative;
  z-index: 0;
`;

export type PageWrapperProps = {
  children: React.ReactNode;
  preview?: boolean;
  headerVisible?: boolean;
  footerMenu?: FooterParsed;
  consentVisible?: boolean;
  header?: IHeaderFields;
  settings?: WebSettingsFragment;
};
export default function PageWrapper({
  children,
  footerMenu,
  preview,
  consentVisible,
  headerVisible = true,
  header,
  settings,
}: PageWrapperProps): ReactElement {
  return (
    <>
      {/* <OrganizationJsonLd
        type="Corporation"
        logo="https://www.example.com/photos/logo.jpg"
        legalName="Purple Fox LLC"
        name="Purple Fox"
        address={{
          streetAddress: "1600 Saratoga Ave",
          addressLocality: "San Jose",
          addressRegion: "CA",
          postalCode: "95129",
          addressCountry: "US",
        }}
        contactPoint={[
          {
            telephone: "+1-401-555-1212",
            contactType: "customer service",
            email: "customerservice@email.com",
            areaServed: "US",
            availableLanguage: ["English", "Spanish", "French"],
          },
          {
            telephone: "+1-877-746-0909",
            contactType: "customer service",
            email: "servicecustomer@email.com",
            contactOption: "TollFree",
            availableLanguage: "English",
          },
          {
            telephone: "+1-877-453-1304",
            contactType: "technical support",
            contactOption: "TollFree",
            areaServed: ["US", "CA"],
            availableLanguage: ["English", "French"],
          },
        ]}
        sameAs={["https://www.orange-fox.com"]}
        url="https://www.purpule-fox.io/"
      />
      <ServiceJsonLd
        type="Service"
        name="Purple Fox"
        description="Purple Fox is a company that does stuff."
        provider={{
          "@type": "Organization",
          name: "Purple Fox",
          sameAs: ["https://www.purple-fox.io"],
        }}
        areaServed={{
          "@type": "Country",
          name: "United States",
        }}
        audience={{
          "@type": "PeopleAudience",
          name: "People",
        }}
        serviceType="Purple Fox Service"
        url="https://www.purple-fox.io/"
      /> */}
      {headerVisible && (
        <MainHeader id="header" data={{ ...header, ...settings }} />
      )}
      {preview && (
        <PreviewModeWrapper>
          You are in preview mode. To exit, click{" "}
          <a href="/api/exit-preview">this</a>.
        </PreviewModeWrapper>
      )}
      <Main>{children}</Main>
    </>
  );
}

const PreviewModeWrapper = styled.div`
  padding: 5px;
  padding-left: 2em;
  background-color: black;
  color: white;
  font-size: 11px;
  a {
    font-size: 11px;
    color: white;
  }
`;
