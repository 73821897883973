import styled from 'styled-components'

export default styled.button`
  border: none;
  background: none;
  padding: 0;
  display: inline-flex;
  width: 1em;
  height: 1em;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  @media print {
    display: none !important;
  }

  &:hover,
  &:focus {
    opacity: 0.7;
  }
`
