import { Attributes } from "react";
import styled from "styled-components";
import { down } from "styled-breakpoints";
import Typography from "src/components/atoms/Typography";
import { BackgroundColorType } from "src/services/contentful/twoStackColumn/twoStackColumnParser";

export interface TextProps extends Attributes {
  backgroundColor?: BackgroundColorType;
  align?: "left" | "center" | "right";
}

export interface BodyProps extends Attributes {
  align: "left" | "center" | "right";
}

export const Wrapper = styled.div`
  overflow: hidden;
`;

/*${({ theme }) => `color: ${theme.palette.common.grey1};`}*/

export const Body = styled.div<BodyProps>`
  font-weigth: 400;
  ${({ align }) => `text-align: ${align};`}
  line-height: 24px;
  text-decoration: none;
  letter-spacing: normal;

  ${down("sm")} {
    padding: 0;
    text-align: center;
  }
`;

export const Title = styled(Typography).attrs({
  variant: "h3",
})<TextProps>`
  ${({ theme, backgroundColor }) =>
    `color: ${
      backgroundColor !== "dark_blue"
        ? theme.palette.common.darkBlue
        : theme.palette.common.white
    };`}
  padding: 0 0 0.5rem 0;
  margin: 0;
`;

export const Excerpt = styled(Typography).attrs({
  variant: "body2",
})<TextProps>`
  ${({ theme, backgroundColor }) =>
    `color: ${
      backgroundColor !== "dark_blue"
        ? theme.palette.common.grey1
        : theme.palette.common.white
    };`}

  ${({ align }) => (align ? `text-align: ${align};` : `text-align: center;`)}
`;
