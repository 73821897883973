import React from "react";
import { useEffect } from "react";
import { useMediaQuery } from "@chakra-ui/react";

import MegaPanel from "../MegaPanel";
import { MainList, MainNavItem, MainNavItemLink } from "./styles";

export const MainMenuList = ({
  data,
  toggleSubMenu,
  a11yClick,
  activeMenus,
  setDrawerOpen,
  drawerOpen,
  isMobile,
}: {
  data: any;
  toggleSubMenu: any;
  a11yClick: any;
  activeMenus: any;
  setDrawerOpen: any;
  drawerOpen: any;
  isMobile: boolean;
}) => {
  const [isMounted, setIsMounted] = React.useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    setIsMounted(true);
  }, []);
  return (
    <MainList
      role="menubar"
      aria-label="Main Navigation"
      aria-orientation="horizontal"
    >
      {data?.menuItems?.map((item: any, i: number) => {
        const { title, linkUrl } = item;
        const ariaPanelId = `menu-Mega-Menu-Panel-${i}`;

        return (
          <MainNavItem
            key={i}
            id="nav-home"
            role="none"
            isActive={!!activeMenus.includes(`menu-Mega-Menu-${i}`)}
          >
            <MainNavItemLink
              id={`menuitem-Mega-Menu-${i}`}
              href={linkUrl ?? "#"}
              //aria-controls={ariaPanelId}
              role="menuitem"
              aria-haspopup={Boolean(item.menuItems?.length > 0)}
              // isActive={!!activeMenus.includes(`menu-Mega-Menu-${i}`)}
              onClick={(e) => toggleSubMenu(e, `menu-Mega-Menu-${i}`, linkUrl)}
              onKeyDown={(e) =>
                a11yClick(e) && toggleSubMenu(e, `menu-Mega-Menu-${i}`, linkUrl)
              }
            >
              {title}
              {item.menuItems?.length > 0 ? (
                <i
                  className={`fas fa-chevron-down ${
                    activeMenus.includes(`menu-Mega-Menu-${i}`) ? "up" : ""
                  }`}
                />
              ) : null}
            </MainNavItemLink>
            {item.menuItems?.length > 0 ? (
              <MegaPanel
                {...{
                  i,
                  ariaPanelId,
                  toggleSubMenu,
                  a11yClick,
                  item,
                  activeMenus,
                  setDrawerOpen,
                  drawerOpen,
                }}
              />
            ) : null}
          </MainNavItem>
        );
      })}
    </MainList>
  );
};

export default MainMenuList;
