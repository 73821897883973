import React from "react";
import styled, { keyframes } from "styled-components";
import { up, down } from "styled-breakpoints";

const megapanelAnimation = keyframes`
0%    { opacity: 0; }
100%  { opacity: 1; }
`;

export const MegaPanelStyleOnly = styled.ul<any>`
  display: flex;
  flex-direction: column;

  @media only screen and (min-width: 768px) {
    position: absolute;
    left: 0;
    top: 50px;
    padding: 3rem;
    display: ${(props) => (props.isActive ? "flex" : "none")};
    flex-direction: row;
    width: 100%;
    height: auto;
    opacity: ${(props) => (props.isActive ? 1 : 0)};
    background-color: #fff;
    box-shadow: 5px 5px 5px rgba(0, 0, 0, 0.3);
    animation: ${megapanelAnimation} 0.5s alternate;

    ul {
      margin: 0;
      padding: 0;
      left: -1em;
      position: relative;
    }
  }
`;

export const NavItem = styled.li`
  display: flex;
  flex-direction: column;
  list-style: none;
  align-items: flex-start;
  justify-content: flex-start;
  height: auto;
  margin: 0;
  padding-left: 1rem;
  padding-right: 1rem;
`;

export const NavItemLink = styled.a`
  padding-bottom: 1rem;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

export const SubMenuSection = styled.li`
  flex-direction: column;
  list-style: none;
`;

export const SubMenuHeading = styled.h5`
  padding-bottom: 1.5rem;
`;

export const MegaList = styled.ul`
  padding-left: 0;
  margin-left: 0;
`;
