/* eslint-disable react-hooks/exhaustive-deps */
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";

import { SearchIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Flex,
  Heading,
  LinkBox,
  LinkOverlay,
  List,
  ListItem,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import axios from "axios";

import debounce from "lodash/debounce";
import Image from "next/image";
import { getTheme } from "src/styles/theme";
import SearchBar from "./SearchBar";

const chakraTheme = getTheme();

const ImageContainer = styled.div`
  width: 100%;

  > div,
  span {
    position: unset !important;
  }

  img {
    object-fit: contain;
    width: 100% !important;
    position: relative !important;
    height: unset !important;
  }
`;

interface SearchResultsProps {
  //client: ContentfulClient;
  query: string;
  pageTypes: string;
}

// Create the search modal component
function SearchModal() {
  // Use the useDisclosure hook from Chakra UI to manage the modal's open/close state
  const { isOpen, onOpen, onClose } = useDisclosure();

  // Create a ref for the search input field
  const searchInputRef = React.useRef();

  const [query, setQuery] = React.useState<string>("");
  const [pageTypes, setPageTypes] = React.useState<string>("pages,posts");

  const [results, setResults] = useState<any[]>([]);
  const [isFetching, setIsFetching] = useState<boolean>(false);
  const [isPageInitialized, setIsPageInitialized] = useState<boolean>(false);
  const router = useRouter();

  const onCloseModal = () => {
    console.log('onCloseModal');
    setQuery("");
    setResults([]);
    onClose();
    console.log('onCloseModal2');
  };

  const fetchResults = async ({ query, pageTypes }: any) => {
    const search = `/api/search?q=${query}&page_types=${pageTypes}`;
    setIsFetching(true);
    axios
      .get(search)
      .then((data) => {
        setResults(data.data);
        // change history for Google Analytics
        if (query && query.length >= 3) {
          const q = { ...router.query, s: query };
          router.replace({
             pathname: router.pathname,
            query: q,
          });
        }
      })
      .catch((err) => {
        console.error(err);
      })
      .finally(() => {
        setIsFetching(false);
      });
  };

  const debounceOnChange = React.useCallback(debounce(fetchResults, 1000), [
    query,
    pageTypes,
  ]);

  useEffect(() => {
    if (query && query.length >= 3 && isPageInitialized) {
      debounceOnChange({ query, pageTypes });
    } else if (query.length >= 3) {
      fetchResults({ query, pageTypes });
    } else if (router.query.s && !isPageInitialized) {
      setIsPageInitialized(true);
      setQuery(router.query.s as string);
      onOpen();
    }
  }, [query, pageTypes, router.query.s]);

  // useEffect(() => {
  //   if (query.length >= 3) {
  //     fetchResults({ query, pageTypes });
  //   }
  // }, [pageTypes, query]);

  // useEffect(() => {
  //   if (router.query.s) {
  //     setQuery(router.query.s as string);
  //     onOpen();
  //   }
  // }, [router.query.s && query.length > 0]);

  const Hit = (hit: any) => {
    const date = new Date(hit?.date_created);

    const localeDateString = date.toLocaleString();
    return (
      <ListItem key={hit?.objectID} m={"0 1em 1em 1em"}>
        <LinkBox bg="#fff" borderRadius={15}>
          <Flex
            direction={["column", "column", "row"]}
            justifyContent="center"
            alignItems="center"
            gap={2}
            p={5}
          >
            <Box
              h={["200px", "200px", "100%"]}
              w={["100%", "100%", "40%"]}
              borderRadius="15px"
              style={{
                borderRadius: "15px",
                overflow: "hidden",
              }}
            >
              <ImageContainer>
                {hit?.cover_image ? (
                  <Image
                    src={`https:${hit?.cover_image}`}
                    fill
                    alt={hit?.title}
                  />
                ) : (
                  <span />
                )}
              </ImageContainer>
            </Box>
            <Box p={5} w={["100%", "100%", "60%"]}>
                <LinkOverlay href={getUrlFromSlug(hit)} onClick={onCloseModal}>
                  <Heading as="h3" size="lg" mb={2}>
                    {hit?.title}
                  </Heading>
                </LinkOverlay>
              <Flex
                direction="row"
                justifyContent="flex-start"
                alignItems="center"
                gap={2}
                mb={3}
              >
                <Flex
                  direction="row"
                  justifyContent="center"
                  alignItems="center"
                  gap={2}
                >
                  {hit?.author_picture ? (
                    <Image
                      src={`https:${hit?.author_picture}`}
                      width={25}
                      height={25}
                      alt={`Picture of ${hit?.author_name}`}
                    />
                  ) : (
                    <span />
                  )}
                  {hit?.author_name}
                </Flex>
                <div>{localeDateString}</div>
              </Flex>

              <Box pt={5}>{hit?.excerpt || hit?.description}</Box>
            </Box>
          </Flex>
        </LinkBox>
      </ListItem>
    );
  };

  const getUrlFromSlug = (hit: any) => {
    if (typeof hit["page_type"] === "undefined") {
      return `${process.env.NEXT_PUBLIC_SITE_URL}blog/${hit?.slug}`;
    }
    if (hit["page_type"] === "pageBlogPost" || hit["page_type"] === "post") {
      return `${process.env.NEXT_PUBLIC_SITE_URL}blog/${hit?.slug}`;
    }

    return `${process.env.NEXT_PUBLIC_SITE_URL}${hit?.slug}`;
  };

  const CustomHits = ({ hits, query }: any) => {
    return hits && hits.length > 0 ? (
      <List p="0" pt="1em">
        {hits && hits.length > 0
          ? hits.map((hit: any, i: number) => (
              <Hit {...hit} index={i} key={i} />
            ))
          : null}
      </List>
    ) : (
      <List p="0" pt="1em">
        <ListItem
          sx={{ background: "#fff", borderRadius: "md", m: 5, mt: 0, p: 4 }}
        >
          {query && query.length >= 3
            ? "No Results Found"
            : "Please enter a search term"}
        </ListItem>
      </List>
    );
  };

  if (typeof window !== "undefined") {
    // needed if SSR
    (window as any).openSearchModal = () => {
      onOpen();
      // set state, what ever you need
    };
  }

  return (
    <>
      {/* Render a button that opens the modal when clicked */}
      <Button
        aria-label="Search Senior Healthcare Direct"
        variant="menu-bar-button"
        onClick={onOpen}
        position="absolute"
        right={[0, 0, 2]}
        zIndex={100}
        rightIcon={<SearchIcon />}
      >
        Search
      </Button>

      {/* Render the modal */}
      <Modal isOpen={isOpen} onClose={onClose} size="4xl">
        <ModalOverlay bg="blackAlpha.300" backdropFilter="blur(5px) " />
        <ModalContent>
          <ModalHeader>
            <Heading as="h1">Site Search</Heading>
          </ModalHeader>
          <ModalCloseButton border="none" style={{ cursor: "pointer" }} />

          <ModalBody>
            <Box>
              {/* Use the InstantSearch component from react-instantsearch-dom to perform search queries */}
              {/* Use the Configure component to specify which attributes to search and how to sort the results */}

              {/* Use the SearchBox component from react-instantsearch-dom to render a search input field */}
              <SearchBar
                refine={setQuery}
                currentRefinement={query}
                setPageTypes={setPageTypes}
                isFetching={isFetching}
              />

              {/* Use the Hits component from react-instantsearch-dom to render the search results */}
              <Box
                bg="#f1f1f1"
                borderRadius={15}
                overflow={"auto"}
                maxHeight={500}
                border="1px solid #ccc"
              >
                <CustomHits hits={results} query={query} />
              </Box>
            </Box>
          </ModalBody>
          <ModalFooter></ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default React.memo(SearchModal);

// import React, { ReactElement, useEffect } from "react";
// import {
//   useDisclosure,
//   Button,
//   Modal,
//   ModalOverlay,
//   ModalHeader,
//   ModalCloseButton,
//   ModalContent,
//   ModalBody,
//   ModalFooter,
//   IconButton,
//   ChakraProvider,
// } from "@chakra-ui/react";
// import { SearchBox } from "react-instantsearch-dom";

// import { SearchIcon } from "@chakra-ui/icons";
// import chakraTheme from "src/styles/chakra-theme";

// const SiteSearch = (): ReactElement => {
//   const { isOpen, onOpen, onClose } = useDisclosure();

//   const handleSizeClick = (newSize) => {
//     console.log("handleSizeClick", newSize);
//     onOpen();
//   };

//   //const sizes = ["xs", "sm", "md", "lg", "xl", "full"];

//   return (
//     <>
//       <ChakraProvider theme={chakraTheme}>
//         <IconButton
//           aria-label="Search Senior Healthcare Direct"
//           size="md"
//           style={{
//             backgroundColor: "transparent",
//             color: "white",
//           }}
//           onClick={handleSizeClick}
//           icon={<SearchIcon />}
//         />
//         <Modal onClose={onClose} size="full" isOpen={isOpen}>
//           <ModalOverlay />
//           <ModalContent>
//             <ModalBody>
//               <ModalCloseButton />
//               {/* <SearchBox
//                 searchAsYouType={false}
//                 submit={<>Search</>}
//                 showLoadingIndicator
//                 translations={{
//                   submitTitle: "Search",
//                   placeholder: "",
//                 }}
//               /> */}
//             </ModalBody>
//             <ModalFooter>
//               <Button onClick={onClose}>Close</Button>
//             </ModalFooter>
//           </ModalContent>
//         </Modal>
//       </ChakraProvider>
//     </>
//   );
// };

// export default SiteSearch;

/*
import React, { useState } from "react";
import ReactDOM from "react-dom";
import Modal from "styled-react-modal";

const StyledModal = Modal.styled`
  width: 20rem;
  height: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  opacity: ${(props) => props.opacity};
  transition : all 0.3s ease-in-out;`;

import { SearchBox } from "react-instantsearch-dom";

import { SearchIcon } from "@chakra-ui/icons";
import { IconButton, ChakraProvider } from "@chakra-ui/react";


const SiteSearch = () => {
  const [isOpen, setIsOpen] = useState(false);

  function toggleModal(e: any) {
    setIsOpen(!isOpen);
  }

  //const sizes = ["xs", "sm", "md", "lg", "xl", "full"];

  return (
    <>
      <ChakraProvider theme={chakraTheme}>
        <IconButton
          aria-label="Search Senior Healthcare Direct"
          size="md"
          style={{
            backgroundColor: "transparent",
            color: "white",
          }}
          onClick={toggleModal}
          icon={<SearchIcon />}
        />
        <StyledModal
          isOpen={isOpen}
          onEscapeKeydown={toggleModal}
          role="dialog"
          aria-modal={true}
          aria-labelledby="modal-label"
        >
          <FocusLock>
            <p id="modal-label">I am an accessible modal!</p>
            <button onClick={toggleModal}>Close me</button>
          </FocusLock>
        </StyledModal>
      </ChakraProvider>
    </>
  );
};

export default SiteSearch;
*/
