/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/anchor-has-content */
// @ts-nocheck
import React, { ReactElement, ReactText, Attributes, ElementType } from "react";
import Link from "next/link";

interface CustomLinkProps extends Attributes {
  href: string;
  className?: string;
  component?: ElementType;
  onClick?: () => void;
  children: React.ReactNode;
}
export function CustomLink({
  href,
  component: Component = "a",
  ...rest
}: CustomLinkProps): ReactElement {
  const isInternalLink = href && href.startsWith("/");
  const isAnchorLink = href && href.startsWith("#");

  if (isInternalLink) {
    return (
      <Link href={href} passHref>
        <Component {...rest} />
      </Link>
    );
  }

  if (isAnchorLink) {
    return <Component href={href} {...rest} />;
  }

  return (
    <Component
      target="_blank"
      rel="noopener noreferrer"
      href={href}
      {...rest}
    />
  );
}

export default CustomLink;
