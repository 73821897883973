import React from "react";
import { TextTopicParsed } from "src/services/contentful/textTopic/textTopicParser";
import TextBlock from "src/components/atoms/TextBlock";
import { Wrapper, Body } from "./styles";
import styled from "styled-components";
import { down, up } from "styled-breakpoints";
import FlexDisplayItem from "src/components/atoms/FlexDisplayItem";
import Image from "next/image";
import siteThemeJSON from "src/styles/theme";
//import svg from "../../../styles/backgrounds/short_dark_03.svg";
import TestSvg from "./test_svg";
import { useEffect, useState } from "@storybook/addons";
import { getThemeTexture } from "src/components/atoms/Section/Section";

export type TextTopicProps = TextTopicParsed & {
  textAlignment: "Left" | "Center" | "Right";
  flexDisplayVerticalAlignment?: "flex-start" | "center" | "flex-end";
  flexDisplayHorizontalAlignment?: "flex-start" | "center" | "flex-end";
  isFullWidth?: boolean;
  fw?: string; // flex width
  theme?:
    | "Light"
    | "Medium"
    | "Dark"
    | "Light Card with Shadow"
    | "Dark Card"
    | "Dark Card with Short Texture"
    | "Dark Card with Short Texture 2"
    | "Dark Card with Short Texture 3";

  header?: string;
};

const Svg1 = styled(TestSvg)`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
`;

const TextBlockWrapper = styled.div`
  z-index: 0;
`;

export const getBackgroundColorBasedOnTheme = (
  themeName?: string
): string | undefined => {
  //console.log("themeName", themeName?.toLowerCase().indexOf("dark white"));
  if (themeName && themeName.toLowerCase().indexOf("form") >= 0) {
    return siteThemeJSON.palette.common.darkWhite;
  } else if (themeName && themeName.toLowerCase().indexOf("light") >= 0) {
    return siteThemeJSON.palette.common.white;
  } else if (themeName && themeName.toLowerCase().indexOf("medium") >= 0) {
    return siteThemeJSON.palette.common.lightBlue;
  } else if (themeName && themeName.toLowerCase().indexOf("dark") >= 0) {
    return siteThemeJSON.palette.common.darkBlue;
  }

  return undefined;
};

export default function TextTopic(data: TextTopicProps): React.ReactElement {
  const {
    header,
    richContent,
    flexDisplayVerticalAlignment,
    flexDisplayHorizontalAlignment,
    textAlignment,
    fw,
    isFullWidth,
    theme,
    backgroundColor,
  } = data;
  let texture = <div />;

  const themeName: string = theme;

  return (
    <FlexDisplayItem
      layoutId={data["data-layoutid"]}
      backgroundColor={getBackgroundColorBasedOnTheme(themeName)}
      fh={flexDisplayHorizontalAlignment || "flex-start"}
      fv={flexDisplayVerticalAlignment || "flex-start"}
      fw={fw ? fw : undefined}
      textAlignment={textAlignment}
      isFullWidth={isFullWidth}
      themeName={themeName}
    >
      {getThemeTexture(themeName)}
      <TextBlockWrapper>
        <TextBlock
          header={header}
          richContent={richContent}
          align={textAlignment}
        />
      </TextBlockWrapper>
    </FlexDisplayItem>
  );
}
