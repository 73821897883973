import * as React from "react";

function SvgComponent(props: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="1440px"
      height="244px"
      viewBox="0 0 1440 244"
      {...props}
    >
      <defs>
        <path id="a" d="M0 0H1440V244H0z" />
      </defs>
      <g
        transform="translate(0 -2178) translate(0 2178)"
        stroke="none"
        strokeWidth={1}
        fill="none"
        fillRule="evenodd"
      >
        <mask id="b" fill="#fff">
          <use xlinkHref="#a" />
        </mask>
        <circle
          stroke="#144974"
          strokeWidth={22}
          mask="url(#b)"
          cx={132.5}
          cy={-62.5}
          r={224.5}
        />
        <circle
          fillOpacity={0.168422886}
          fill="#002955"
          mask="url(#b)"
          cx={132.5}
          cy={-62.5}
          r={171.5}
        />
        <circle
          fillOpacity={0.168422886}
          fill="#002955"
          mask="url(#b)"
          cx={1271}
          cy={100}
          r={46}
        />
        <circle
          stroke="#144974"
          strokeWidth={22}
          mask="url(#b)"
          cx={1399}
          cy={237}
          r={145}
        />
      </g>
    </svg>
  );
}

export default SvgComponent;
