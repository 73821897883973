import React from "react";
import { H1, H2, H3, H4, H5, H6, Body1, Body2 } from "./styles";

export interface TypographyProps {
  variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "body1" | "body2";
  component?: React.ElementType | keyof JSX.IntrinsicElements;
}

const Typography = React.forwardRef(
  ({ variant = "body2", component, ...rest }: any, ref) => {
    const props = {
      as: component,
      ref,
      ...rest,
    };
    switch (variant) {
      case "h1":
        return <H1 {...props} />;
      case "h2":
        return <H2 {...props} />;
      case "h3":
        return <H3 {...props} />;
      case "h4":
        return <H4 {...props} />;
      case "h5":
        return <H5 {...props} />;
      case "h6":
        return <H6 {...props} />;
      case "body1":
        return <Body1 {...props} />;
      default:
        return <Body2 {...props} />;
    }
  }
);

Typography.displayName = "Typography";

export default Typography;
