import React from "react";

import {
  Box,
  Checkbox,
  CheckboxGroup,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Spinner,
  Stack
} from "@chakra-ui/react";

export type SearchBarProps = {
  refine: (query: string) => void;
  currentRefinement: string;
  setPageTypes: (pageTypes: string) => void;
  isFetching: boolean;
};

const SearchBar = ({
  isFetching,
  refine,
  currentRefinement,
  setPageTypes,
}: SearchBarProps) => {
  return (
    <Stack pb={5} spacing={1}>
      <Box height={45}>
        <FormControl variant="floating" id="search-input">
          <InputGroup width={"100%"}>
            <InputLeftElement width={"100%"} sx={{ display: "block" }}>
              <Input
                id="search-input"
                size="lg"
                type="text"
                placeholder=" "
                width={"100%"}
                value={currentRefinement}
                autoFocus={true}
                onChange={(event: any) => refine(event.currentTarget.value)}
                onBlur={(event: any) => refine(event.currentTarget.value)}
              />
              <FormLabel htmlFor="search-input">Search</FormLabel>
            </InputLeftElement>
            <InputRightElement
              sx={{ display: isFetching ? "block" : "none" }}
              pointerEvents="none"
              marginRight={2}
              p={3}
              // eslint-disable-next-line react/no-children-prop
              children={<Spinner color="brandAccent.500" />}
            />
          </InputGroup>
        </FormControl>
      </Box>
      <Box>
        <CheckboxGroup
          onChange={(data) => {
            setPageTypes(data.toString());
          }}
          colorScheme="blue"
          defaultValue={["posts", "pages"]}
        >
          <Stack mt={5} spacing={[1, 5]} direction={["column", "row"]}>
            <Checkbox value="posts">Blog Posts</Checkbox>
            <Checkbox value="pages">Pages</Checkbox>
          </Stack>
        </CheckboxGroup>
      </Box>
    </Stack>
  );
};

export default React.memo(SearchBar);
