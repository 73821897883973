import { AddIcon, MinusIcon } from "@chakra-ui/icons";
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs as TabbyTabs,
  useBreakpointValue,
} from "@chakra-ui/react";

import { useEffect } from "@storybook/addons";
import React, { useState } from "react";
import { BlockRenderer } from "src/components/molecules/BlockRenderer";
import { IFlexDisplay, ITabsFields } from "src/types/generated/contentful";
import { down } from "styled-breakpoints";

const Tabber = ({ content, tabDirection }: ITabsFields) => {
  return (
    <TabbyTabs
      variant={tabDirection === "Vertical" ? "vertical" : undefined}
      orientation={tabDirection === "Vertical" ? "vertical" : "horizontal"}
      display={tabDirection === "Vertical" ? "flex" : "block"}
      margin={"2em 0"}
    >
      <TabList
        flexDirection={tabDirection === "Vertical" ? "column" : "row"}
        width={tabDirection === "Vertical" ? "29%" : "inherit"}
      >
        {content &&
          content.map((c: any, i: number) => {
            //console.log("tab", c);
            const { title } = c.fields;
            return (
              <Tab {...{ tabDirection }} key={i}>
                <h6>{title}</h6>
              </Tab>
            );
          })}
      </TabList>

      <TabPanels width={tabDirection === "Vertical" ? "70%" : "inherit"}>
        {content &&
          content.map((c: any, i: number) => {
            return (
              <TabPanel {...{ tabDirection }} key={i}>
                <div className="panel-content">
                  <BlockRenderer block={c} layoutId="test" />
                </div>
              </TabPanel>
            );
          })}
      </TabPanels>
    </TabbyTabs>
  );
};

const Accord = ({ content, tabDirection }: ITabsFields) => {
  return (
    <Accordion allowToggle defaultIndex={[0]}>
      {content &&
        content.map((c: any, i: number) => {
          const { title } = c.fields;
          return (
            <AccordionItem key={i}>
              {({ isExpanded }) => (
                <>
                  <h6>
                    <AccordionButton>
                      <Box as="span" flex="1" textAlign="left">
                        {title}
                      </Box>

                      {isExpanded ? (
                        <MinusIcon fontSize="12px" />
                      ) : (
                        <AddIcon fontSize="12px" />
                      )}
                    </AccordionButton>
                  </h6>

                  <AccordionPanel {...{ tabDirection }} key={i}>
                    <div className="panel-content">
                      <BlockRenderer block={c} layoutId="test" />
                    </div>
                  </AccordionPanel>
                </>
              )}
            </AccordionItem>
          );
        })}
    </Accordion>
  );
};
export interface TabsProps extends ITabsFields {
  layoutId?: string;
}
const Tabs = (data: TabsProps) => {
  //console.log("Tabs", JSON.stringify(data));
  const { content, tabDirection, title } = data;
  //const tabDirection = "Horizontal";
  const isMobile = useBreakpointValue({ base: true, md: false });
  const tabd = isMobile ? "Horizontal" : tabDirection;
  //console.log("tabDirection", tabDirection);

  return !isMobile ? (
    <Tabber {...{ title, content, tabDirection }} />
  ) : (
    <Accord {...{ title, content, tabDirection }} />
  );
};

export default React.memo(Tabs);
