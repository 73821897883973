import FlexDisplayItem from "src/components/atoms/FlexDisplayItem";
import styled from "styled-components";
import { BlockRenderer } from "../BlockRenderer";

const SubMenuItemWrapper = styled.div``;

export default function SubMenuItem(args: any) {
  // console.log("SubMenuItem", args);
  const { title, menuItems, showTitle, layoutId } = args;
  return (
    <FlexDisplayItem {...args}>
      {showTitle ? <h3>{title}</h3> : null}
      <SubMenuItemWrapper>
        {menuItems.map((item: any, i: number) => {
          // console.log("item", item);
          return (
            <BlockRenderer layoutId={`${layoutId}-${i}`} key={i} block={item} />
          );
        })}
      </SubMenuItemWrapper>
    </FlexDisplayItem>
  );
}
