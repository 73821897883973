import { ChakraTheme } from "@chakra-ui/react";
import dynamic from "next/dynamic";
import shdTheme from "./brands/shd";
import starterTheme from "./brands/starter";
import { baloo2, montserrat } from "./brands/shd/foundations/typography";

declare module "@emotion/react" {
  export interface Theme extends ChakraTheme {}
}

export const getTheme = (): Record<string, any> => {
  const themeName = process.env.THEME_NAME;

  return shdTheme;
};

const chakraTheme = getTheme();

const colors = {
  white: chakraTheme.colors.white,
  black: chakraTheme.colors.black,
  grey1: chakraTheme.colors.old.grey1,
  grey2: chakraTheme.colors.old.grey2,
  grey3: chakraTheme.colors.old.grey3,
  grey4: chakraTheme.colors.old.grey4,
  grey5: chakraTheme.colors.old.grey5,
  grey6: chakraTheme.colors.old.grey6,
  lightCabaret: chakraTheme.colors.old.lightCabaret,
  cabaret: chakraTheme.colors.old.cabaret,
  lightBlue: chakraTheme.colors.brandSecondary[500],
  darkBlue: chakraTheme.colors.brandPrimary[500],
  darkWhite: chakraTheme.colors.brandTertiary[500],
  red: chakraTheme.colors.old.red,
};

const palette = {
  common: colors,
  primary: {
    accessibleLightPrimary:
      chakraTheme.colors.old.primary.accessibleLightPrimary,
    light: chakraTheme.colors.old.primary.light,
    main: chakraTheme.colors.old.primary.main,
    dark: chakraTheme.colors.old.primary.dark,
    menuLight: chakraTheme.colors.old.primary.menuLight,
    menuDark: chakraTheme.colors.old.primary.menuDark,
    contrastText: colors.white,
  },
  text: {
    heading: colors.darkBlue,
    body: colors.darkBlue,
  },
};
const baseFontSize = 1;

const fontFamilies = {
  primary: montserrat.style.fontFamily,
  secondary: baloo2.style.fontFamily,
};
const typography = {
  fontFamilies,
  fontFamily: fontFamilies.primary,
  fontSize: baseFontSize,
  fontWeight: 400,
  lineHeight: 1.5,
  a: {
    fontWeight: 800,
    textDecoration: "underline",
  },
  p: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize,
    fontWeight: 400,
    lineHeight: baseFontSize * 1.8,
    letterSpacing: 0.01,
    mobile: {
      fontSize: baseFontSize * 1.2,
      lineHeight: baseFontSize * 1.8,
    },
  },
  h1: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 4,
    fontWeight: 800,
    lineHeight: baseFontSize * 4.3,
    letterSpacing: 0.01,
    padding: "0",
    margin: "2rem 0",
    mobile: {
      fontSize: baseFontSize * 1.6,
      lineHeight: baseFontSize * 1.9,
    },
  },
  h2: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 3,
    fontWeight: 800,
    lineHeight: baseFontSize * 3.3,
    letterSpacing: 0.01,
    padding: "0",
    margin: "2rem 0",
    mobile: {
      fontSize: baseFontSize * 1.5,
      lineHeight: baseFontSize * 1.7,
    },
  },
  h3: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 2,
    fontWeight: 800,
    lineHeight: baseFontSize * 2.3,
    letterSpacing: 0.01,
    padding: "0",
    margin: "2rem 0",
    mobile: {
      fontSize: baseFontSize * 1.4,
      lineHeight: baseFontSize * 1.7,
      letterSpacing: 0.01,
    },
  },
  h4: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.3,
    fontWeight: 600,
    lineHeight: baseFontSize * 1.6,
    letterSpacing: 0.01,
    padding: "0",
    margin: "0.5rem 0",
    mobile: {
      fontSize: baseFontSize * 1.4,
      lineHeight: baseFontSize * 1.7,
      letterSpacing: 0,
    },
  },
  h5: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.4,
    fontWeight: 600,
    lineHeight: baseFontSize * 1.7,
    padding: "0",
    margin: "0.5rem 0",
    mobile: {
      fontSize: baseFontSize * 1.2,
      lineHeight: baseFontSize * 1.4,
    },
  },
  h6: {
    fontFamily: fontFamilies.secondary,
    fontSize: baseFontSize * 1.25,
    fontWeight: 600,
    lineHeight: baseFontSize * 1.45,
    padding: "0",
    margin: "0.5rem 0",
    mobile: {
      fontSize: baseFontSize * 1.2,
      fontWeight: 600,
      lineHeight: baseFontSize * 1.6,
    },
  },
  body1: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize * 1.25,
    fontWeight: 400,
    lineHeight: 1.5,
    mobile: {
      fontSize: baseFontSize,
      lineHeight: 1.4,
    },
  },
  body2: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize,
    fontWeight: 400,
    lineHeight: 1.5,
    mobile: {
      fontSize: baseFontSize * 0.9,
      lineHeight: 1.3,
    },
  },
  buttonMedium: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize * 0.7,
    fontWeight: 700,
    lineHeight: baseFontSize * 0.7,
    letterSpacing: 0.1,
    textTransform: "uppercase",
    mobile: {
      fontSize: baseFontSize * 0.8,
    },
  },
  buttonSmall: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize * 0.6,
    fontWeight: 700,
    lineHeight: baseFontSize * 0.6,
    textTransform: "uppercase",
    mobile: {
      fontSize: baseFontSize * 0.75,
    },
  },
  sm: {
    fontFamily: fontFamilies.primary,
    fontSize: baseFontSize * 0.6,
    fontWeight: 400,
    lineHeight: baseFontSize * 0.6,
    mobile: {
      fontSize: baseFontSize * 0.6,
    },
  },
};
const size = {
  desktopMaxWidth: 1366,
  gutter: 15,
};
const components = {
  componentRichTextBaseLight: {
    backgroundColor: palette.common.white,
    textColor: palette.common.darkBlue,
    fontSize: "1.5em",
    fontWeight: 600,
    padding: "3em 15em",
  },
  componentRichTextBaseDark: {
    backgroundColor: palette.common.darkBlue,
    textColor: palette.common.white,
    fontSize: "1.5em",
    fontWeight: 600,
    padding: "3em 15em",
  },
  componentRichTextMainHeaderSupplement: {
    backgroundColor: palette.common.darkBlue,
    textColor: palette.common.white,
    fontSize: "1.5em",
    fontWeight: 600,
    padding: "0em 0em",
  },
  componentRichTextLandingFooter: {
    backgroundColor: palette.common.darkBlue,
    textColor: palette.common.grey3,
    fontSize: "1em",
    fontWeight: 400,
    padding: "1em",
  },
};

// Exports
const theme = {
  components,
  palette,
  typography,
  size,
};

export default theme;
