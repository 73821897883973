/* eslint-disable react/display-name */

import get from "lodash/get";
import React from "react";
import type { Entry } from "contentful";
import dynamic from "next/dynamic";
import { IPage } from "src/types/generated/contentful";

import {
  PageContentTypes,
  ComponentContentTypes,
  PageContentType,
} from "../../lib/constants";

const AnnualReview = dynamic(() => import("../../pages/annualReview"));
const AnnualReviewHeader = dynamic(
  () => import("../organisms/AnnualReviewHeader")
);

import Section from "../atoms/Section";
import SubMenuItem from "./SubMenuItem";
import MenuItem from "./MenuItem";
import Tabs from "../atoms/Tabs";
import RichText from "../atoms/RichText";

const FlexDisplayItem = dynamic(() => import("../atoms/FlexDisplayItem"));

const VideoTopic = dynamic(() => import("./VideoTopic"));
const TextTopic = dynamic(() => import("./TextTopic"));
const ImageTopic = dynamic(() => import("../atoms/ImageTopic"));
const Testimonials = dynamic(() => import("./Testimonials"));
const Hero = dynamic(() => import("../organisms/Hero"));
const MainHeaderContent = dynamic(() => import("../atoms/MainHeaderContent"));
const NascarLogos = dynamic(() => import("./NascarLogos"));
const TeamMember = dynamic(() => import("../organisms/TeamMember"));
const Button = dynamic(() => import("../atoms/Button"));
const FlexDisplay = dynamic(() => import("../atoms/FlexDisplay"));
const TestimonialsSlider = dynamic(() => import("./TestimonialsSlider"));
const Footnote = dynamic(() => import("../atoms/Footnote"));
const Heading = dynamic(() => import("../atoms/Heading"));
const SourceTopic = dynamic(() => import("../atoms/SourceTopic"));
const FourZeroFour = dynamic(() => import("../molecules/FourZeroFour"));
const Form = dynamic(() => import("../forms/Form"));
const Collection = dynamic(() => import("../molecules/Collection"));
const FeaturedPostCard = dynamic(() => import("../molecules/FeaturedPostCard"));
const OrgContactInfo = dynamic(() => import("../atoms/OrgContactInfo"));
const Faq = dynamic(() => import("../organisms/Faq"));

type BlockRendererProps = {
  block: any;
  layoutId: string; // this is used to give a unique id to each component for css styling
  fw?: number; // flex width for texttopic, image topic, etc
};

const BlockRenderer = (data: BlockRendererProps) => {
  const { block, fw } = data;
  let layoutId = data.layoutId ? data.layoutId : "L";
  // console.log("layoutId:B", layoutId);

  if (block?.fields?.internalName === "All Articles") {
    //console.log("BlockRenderer", block?.fields?.internalName);
  }

  if (Array.isArray(block)) {
    return (
      <>
        {block.map((b: IPage, i: number) => (
          <>
            <BlockRenderer
              key={`block-${b.sys.id}`}
              layoutId={`${layoutId}-${i}`}
              block={b}
              fw={fw}
            />
          </>
        ))}
      </>
    );
  }

  const contentTypeId =
    block && block.__typename
      ? camelize(block.__typename)
      : get(block, "sys.contentType.sys.id");

  const Component = ContentTypeMap[contentTypeId];

  if (!Component) {
    if (contentTypeId) {
      console.warn(`${contentTypeId} can not be handled`);
    }
    // console.log(
    //   "BlockRenderer",
    //   contentTypeId,
    //   ContentTypeMap[contentTypeId],
    //   block
    // );
    return null;
  }

  const { id } = block.sys;

  const componentProps = {
    ...block,
    parent: block.parent,
  };

  return (
    <Component
      key={`${contentTypeId}-${id}`}
      data-layoutid={layoutId}
      {...componentProps.fields}
      fw={fw}
    />
  );
};

{
  /* <pre>{JSON.stringify(parent, null, 2)}</pre> */
}

const fromPage = (fieldName: string) => (parent: Entry<any>) => {
  const t = parent?.fields[fieldName] as any;

  return (
    <>
      <BlockRenderer
        layoutId={"l-"}
        key={"SHDPage-" + Math.random()}
        block={{ ...t, parent }}
      />
    </>
  );
};

const ContentTypeMap = {
  [ComponentContentTypes.RichContent]: RichText,
  [ComponentContentTypes.NascarLogos]: NascarLogos,
  [ComponentContentTypes.Hero]: Hero,
  [ComponentContentTypes.TextTopic]: TextTopic,
  [ComponentContentTypes.Section]: Section,
  [ComponentContentTypes.FlexDisplay]: FlexDisplay,
  [ComponentContentTypes.ImageTopic]: ImageTopic,
  [ComponentContentTypes.VideoTopic]: VideoTopic,
  [ComponentContentTypes.SubMenuItem]: SubMenuItem,
  [ComponentContentTypes.MenuItem]: MenuItem,
  [ComponentContentTypes.Button]: Button,
  [ComponentContentTypes.FlexDisplayItem]: FlexDisplayItem,
  [ComponentContentTypes.FootNote]: Footnote,
  [ComponentContentTypes.TeamMember]: TeamMember,
  [ComponentContentTypes.Heading]: Heading,
  [ComponentContentTypes.SourceTopic]: SourceTopic,
  [ComponentContentTypes.FourZeroFour]: FourZeroFour,
  [ComponentContentTypes.MainContactForm]: Form,
  [ComponentContentTypes.CustomContactForm]: Form,
  [ComponentContentTypes.FeaturedPostCard]: FeaturedPostCard,
  [ComponentContentTypes.OrgContactInfo]: OrgContactInfo,
  [ComponentContentTypes.Faq]: Faq,

  [ComponentContentTypes.Tabs]: Tabs,

  [ComponentContentTypes.Testimonials]: Testimonials,
  [ComponentContentTypes.AnnualReviewHeader]: AnnualReviewHeader,
  [ComponentContentTypes.MainHeaderContent]: MainHeaderContent,
  [ComponentContentTypes.TestimonialsSlider]: TestimonialsSlider,
  [ComponentContentTypes.Collection]: Collection,

  [PageContentTypes.AnnualReview]: AnnualReview,
  [PageContentType]: fromPage("content"),
};

function camelize(str: string): string {
  return str
    .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
      return index === 0 ? word.toLowerCase() : word.toUpperCase();
    })
    .replace(/\s+/g, "");
}

// [ComponentContentTypes.Section]: Section,
// [ComponentContentTypes.Text]: Text,
// [ComponentContentTypes.Image]: Image,
// [ComponentContentTypes.Video]: Video,

export { BlockRenderer };
