import FlexDisplayItem from "src/components/atoms/FlexDisplayItem";
import styled from "styled-components";
import { BlockRenderer } from "../BlockRenderer";

const SubMenuItemWrapper = styled.div``;

export default function SubMenuItem(args: any) {
  const { title, link } = args;
  return (
    <FlexDisplayItem {...args}>
      <a href={link}>{title}</a>
    </FlexDisplayItem>
  );
}
