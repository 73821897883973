import { extendTheme } from "@chakra-ui/react";

//import { extendTheme, StyleFunctionProps } from "@chakra-ui/react";

import { GlobalStyleProps } from "@chakra-ui/theme-tools";
import foundations from "./foundations";
import Logo from "./logo";
import { baloo2, montserrat } from "./foundations/typography";

const inputBorderColor = "#a5a5a5";

const direction = "ltr";

const config = {
  useSystemColorMode: false,
  initialColorMode: "light",
  cssVarPrefix: "chakra",
};

const brandSolidLight = {
  bg: "gray.50",
  color: "brandprimary.500",
  textTransform: "uppercase",
  fontFamily: montserrat.style.fontFamily,
  borderRadius: 24,
  width: "fit-content",
  mt: 5,
  fontSize: "sm",
  fontWeight: "bold",
  px: 6,
  py: 3,
  _active: {
    bg: "brandAccent.500",
    color: "gray.50",
  },
  _hover: {
    textDecoration: "none",
    bg: "gray.300",
    color: "brandPrimary.500",
  },
};

// {
//   bg: "brandSecondary.500",
//   textTransform: "uppercase",
//   fontFamily: "'Montserrat',sans-serif",
//   color: "brandPrimary.500",
//   _active: {
//     bg: "brandSecondary.900",
//     color: "brandPrimary.900",
//   },
//   _hover: {
//     bg: "brandSecondary.700",
//     color: "brandPrimary.700",
//   },
// },
// "with-shadow": {
//   bg: "red.400",
//   boxShadow: "0 0 2px 2px #efdfde",
// }

const brandSolidAccent = {
  bg: "brandAccent.600",
  textTransform: "uppercase",
  fontFamily: montserrat.style.fontFamily,
  border: "2px",
  borderColor: "brandAccent.600",
  width: "fit-content",
  borderStyle: "solid",
  borderRadius: 24,
  mt: 5,
  fontSize: "md",
  fontWeight: "bold",
  px: 6,
  py: 3,
  color: "gray.50",
  _active: {
    bg: "brandPrimary.500",
    color: "gray.50",
  },
  _hover: {
    textDecoration: "none",
    bg: "brandAccent.800 !important",
    color: "gray.50",
  },
};

// {
//   bg: "gray.50",
//   textTransform: "uppercase",
//   fontFamily: "'Montserrat',sans-serif",
//   color: "brandAccent.600",
//   borderColor: "brandAccent.600",
//   border: "1px solid",
//   _active: {
//     bg: "brandPrimary.500",
//     color: "gray.50",
//   },

//   _hover: {
//     bg: "gray.300",
//     color: "brandAccent.600",
//   },
// }
const brandOutlineAccent = {
  bg: "gray.50",
  color: "brandAccent.600",
  width: "fit-content",
  textTransform: "uppercase",
  fontFamily: montserrat.style.fontFamily,
  border: "2px",
  borderColor: "brandAccent.500",
  borderStyle: "solid",
  borderRadius: 24,
  mt: 5,
  fontSize: "md",
  fontWeight: "bold",
  px: 6,
  py: 3,
  _active: {
    bg: "gray.100",
    color: "brandAccent.600",
  },
  _hover: {
    textDecoration: "none",
    bg: "#c0d7d9",
    color: "brandAccent.600",
  },
};

const activeLabelStyles = {
  transform: "scale(0.85) translateY(-25px)",
  color: "#616161",
};

export const formComponent = {
  Form: {
    variants: {
      floating: {
        container: {
          _focusWithin: {
            label: {
              ...activeLabelStyles,
            },
          },
          "input:not(:placeholder-shown) + label, .chakra-select__wrapper + label, textarea:not(:placeholder-shown) ~ label":
            {
              ...activeLabelStyles,
            },
          label: {
            top: 0,
            left: 0,
            zIndex: 2,
            color: "black", //"#4c535f",
            position: "absolute",
            backgroundColor: "white",
            pointerEvents: "none",
            mx: 3,
            px: 1,
            my: 3,
            transformOrigin: "left top",
            fontFamily: montserrat.style.fontFamily,
          },
        },
      },
    },
  },
};

export const checkboxComponent = {
  Checkbox: {
    baseStyle: {
      control: {
        borderWidth: "1px",
        borderRadius: "25px",
        borderColor: inputBorderColor,
        width: "20px",
        height: "20px",
        _checked: {
          bg: "brandAccent.500",
          borderColor: "brandAccent.500",
        },
      },
      container: {},
    },
  },
};

export const tabComponent = {
  Tabs: {
    baseStyle: {
      root: {
        backgroundColor: "brandSecondary.500",
        p: "1em",
        boxShadow: "0px 0px 35px 2px rgb(0 55 100 / 10%)",
        borderColor: "transparent !important",
      },

      tab: {
        borderBottom: "none",
        _hover: {
          color: "brandPrimary.500",
          backgroundColor: "brandSecondary.600",
        },
        _focus: {
          boxShadow: "0 0 0 3px brandAccent.600",
        },
        _selected: {
          color: "brandPrimary.500",
          fontWeight: "bold",
          backgroundColor: "white",
          borderBottom: "none",
        },
        color: "brandPrimary.500",
        fontFamily: montserrat.style.fontFamily,
        fontWeight: "bold",
        fontSize: "xl",
        px: 4,
        py: 3,
        _last: {},
      },
      tablist: {},
      tabpanel: {
        backgroundColor: "white",
        px: 4,
        py: 3,
        _last: {},
      },
    },
    variants: {
      vertical: {
        tab: {
          _selected: {},
        },
        tablist: {
          //borderRightColor: "inherit",
        },
      },
    },
  },
};

export const accordionComponent = {
  Accordion: {
    baseStyle: {
      container: {},
      button: {
        _hover: {
          bg: "brandAccent.500",
          color: "white",
        },
        _focus: {
          boxShadow: "0 0 0 3px brandAccent.500",
          boxShadowColor: "brandAccent.500",
        },
        _expanded: {
          bg: "brandPrimary.500",
          color: "white",
        },
        bg: "white",
        color: "brandPrimary.500",
        fontFamily: montserrat.style.fontFamily,
        fontWeight: "bold",
        fontSize: "xl",
        px: 4,
        py: 3,
        _last: {},
      },
      panel: {
        px: 4,
        py: 3,
        _last: {},
      },
    },
  },
};

export const inputComponent = {
  Select: {
    variants: {
      outline: {
        field: {
          border: "1px solid",
          borderColor: inputBorderColor,
          bg: "inherit",
          _focus: {
            borderColor: "brandAccent.500",
          },
        },
      },
    },

    defaultProps: {
      variant: "outline",
      size: "lg",
    },
  },
  Input: {
    variants: {
      text: {
        field: {
          //width: "100%",
          //padding: "0.5rem 1.25rem",
          //display: "inline-block",
          border: `1px solid ${inputBorderColor}`,
          //boxSizing: "border-box",
          //my: "0.25rem",
          //            bg: "#E7F0FD",
          fontFamily: montserrat.style.fontFamily,
          _focus: {
            border: "2px solid",
            borderColor: "brandAccent.500",
          },
        },
      },
    },
    defaultProps: {
      variant: "text",
    },
  },
};

export const theme = {
  media: {
    logo: Logo,
  },
  styles: {
    global: (props: GlobalStyleProps) => ({
      "html, body": {
        scrollBehavior: "smooth",
        // "-webkit-font-smoothing": "antialiased",
        // "-moz-osx-font-smoothing": "grayscale",
      },
      "ul,li,ol,p": {
        color: "brandPrimary.500",
      },
      li: {
        width: "inherit",
      },
      a: {
        fontWeight: "bold",
        color: "brandAccent.600",
        textDecoration: "none",
        _hover: {
          textDecoration: "underline",
        },
      },
      p: {
        fontSize: ["lg"],
        fontFamily: montserrat.style.fontFamily,
        lineHeight: "150%",
        color: "brandPrimary.500",
        margin: "0.5rem 0",
        padding: 0,
      },
      h1: {
        fontSize: ["4xl", "6xl"],
        fontFamily: baloo2.style.fontFamily,
        fontWeight: "extrabold",
        lineHeight: "110%",
        color: "brandPrimary.500",
        margin: "2rem 0",
        padding: 0,
      },
      h2: {
        fontSize: ["3xl", "5xl"],
        fontFamily: baloo2.style.fontFamily,
        fontWeight: "extrabold",
        lineHeight: "110%",
        color: "brandPrimary.500",
        margin: "2rem 0",
        padding: 0,
      },
      h3: {
        fontSize: "4xl",
        fontFamily: baloo2.style.fontFamily,
        fontWeight: "bold",
        lineHeight: "110%",
        color: "brandPrimary.500",
        margin: "1.5rem 0",
        padding: 0,
      },
      h4: {
        fontSize: "3xl",
        fontFamily: baloo2.style.fontFamily,
        fontWeight: "semibold",
        lineHeight: "110%",
        color: "brandPrimary.500",
        margin: "1rem 0",
        padding: 0,
      },
      h5: {
        fontSize: "2xl",
        fontFamily: baloo2.style.fontFamily,
        fontWeight: "semibold",
        lineHeight: "110%",
        color: "brandPrimary.500",
        margin: "0.5rem 0",
        padding: 0,
      },
      h6: {
        fontSize: "xl",
        fontFamily: baloo2.style.fontFamily,
        fontWeight: "semibold",
        lineHeight: "110%",
        color: "brandPrimary.500",
        margin: "0.5rem 0",
        padding: 0,
      },
    }),
  },
  direction,
  ...foundations,
  config,

  components: {
    ...accordionComponent,
    ...formComponent,
    ...inputComponent,
    ...tabComponent,
    ...checkboxComponent,
    FormErrorMessage: {
      baseStyle: {
        color: "red.900",
      },
    },
    Link: {
      baseStyle: {
        color: "brandAccent.500",
      },
      variants: {
        "brand-solid-light": brandSolidLight,
        "brand-solid-accent": brandSolidAccent,
        "brand-outline-accent": brandOutlineAccent,
      },
    },
    Button: {
      // 1. We can update the base styles
      baseStyle: {
        fontWeight: "bold", // Normally, it is "semibold"
        border: "0",
        borderRadius: "3em",
        fontSize: "lg",
        cursor: "pointer",
      },
      // 2. We can add a new button size or extend existing
      sizes: {
        xl: {
          h: "56px",
          px: "32px",
        },
      },
      // 3. We can add a new visual variant
      variants: {
        "menu-bar-button": {
          border: "0px solid rgba(255, 255, 255, 0.35)",
          backgroundColor: "transparent",
          color: "white",
          fontFamily: montserrat.style.fontFamily,
          fontSize: "1rem",
          minWidth: "inherit",
          padding: "0 1rem",
          borderRadius: "0.3rem",
          height: "30px",
        },
        "brand-solid-accent": brandSolidAccent,
        "brand-outline-accent": brandOutlineAccent,
        "brand-solid-light": brandSolidLight,
        // 4. We can override existing variants
        // TODO: add back StyleFunctionProps instead of any v2 chakra-ui/react

        solid: (props: any) => ({
          bg: props.colorMode === "dark" ? "red.300" : "red.500",
        }),
        // 5. We can add responsive variants
        sm: {
          bg: "teal.500",
          fontSize: "md",
        },
      },
      // 6. We can overwrite defaultProps
      defaultProps: {
        size: "lg", // default is md
        variant: "sm", // default is solid
        colorScheme: "green", // default is gray
      },
    },
  },
};

export default extendTheme(theme);
