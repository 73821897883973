/* eslint-disable @next/next/no-img-element */
// @ts-nocheck
// TODO: remove no check
import "normalize.css";

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { ReactElement, useEffect, useState } from "react";

import { ChakraProvider } from "@chakra-ui/react";
import { AppProps } from "next/dist/shared/lib/router/router";
import Head from "next/head";

import Script from "next/script";

import { queryConfig } from "@src/lib/gql-client";
import TagManager from "react-gtm-module";
import { ContentfulProvider } from "src/components/ContentfulProvider";
import PageWrapper, { PageWrapperProps } from "src/components/PageWrapper";
import SourceTopicProvider from "src/components/atoms/SourceTopic/SourceTopicContext";
import theme, { getTheme } from "src/styles/theme";
import { StyleSheetManager, ThemeProvider } from "styled-components";

// import { ChakraProvider } from "@chakra-ui/react";
// import {
//   ThemeEditorProvider,
//   HyperThemeEditor,
// } from "@hypertheme-editor-pro/chakra-ui";
// import chakraTheme from "../src/styles/chakra-theme";

const gtmId = process.env.NEXT_PUBLIC_GTM_ID;
const chakraTheme = getTheme();

interface MyAppProps extends AppProps {
  pageProps: PageWrapperProps;
}

export default function MyApp({
  Component,
  pageProps,
}: MyAppProps): ReactElement {
  const slug = pageProps?.page?.fields?.slug;

  const [queryClient] = useState(() => new QueryClient(queryConfig));

  useEffect(() => {
    if (gtmId) {
      TagManager.initialize({
        gtmId,
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
      });
    }
  }, []);

  const isServerSideRender = typeof window === "undefined";

  {
    /*<ChakraProvider theme={chakraTheme}>
      <ThemeEditorProvider>
        <HyperThemeEditor pos="fixed" bottom={4} right={2} />
      </ThemeEditorProvider>
  </ChakraProvider>*/
  }


  return (
    <ContentfulProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />

        <ChakraProvider theme={chakraTheme} resetTheme={false}>
          <StyleSheetManager disableCSSOMInjection={!isServerSideRender}>
            <ThemeProvider theme={theme}>
              <SourceTopicProvider>
                <Head>
                  <meta
                    name="viewport"
                    content="width=device-width, initial-scale=1, user-scalable=yes"
                  />
                </Head>
                {/*<GlobalStyles />*/}
                {/*<DefaultSeo {...MAIN_SITE_SEO} />*/}
                <PageWrapper
                  preview={pageProps?.preview}
                  footerMenu={pageProps?.footerMenu}
                  consentVisible={pageProps?.consentVisible}
                  headerVisible={pageProps?.headerVisible}
                  header={pageProps?.header}
                  settings={pageProps?.settings}
                >
                  <Component {...pageProps} />
                </PageWrapper>

                {/* Generate a TrustedForm Certificate */}
                <Script id="trustedForm" type="module">
                  {`(function() {
      var field = 'xxTrustedFormCertUrl';
      var provideReferrer = false;
      var tf = document.createElement('script');
      tf.type = 'text/javascript'; tf.async = true;
      tf.src = 'http' + ('https:' == document.location.protocol ? 's' : '') +
      '://api.trustedform.com/trustedform.js?provide_referrer=' + escape(provideReferrer) + '&field=' + escape(field) + '&l='+new Date().getTime()+Math.random();
      var s = document.getElementsByTagName('script')[0]; s.parentNode.insertBefore(tf, s); }
    )();`}
                </Script>
                <noscript>
                  <img src="https://api.trustedform.com/ns.gif" alt="" />
                </noscript>
              </SourceTopicProvider>
            </ThemeProvider>
          </StyleSheetManager>
        </ChakraProvider>
      </QueryClientProvider>
    </ContentfulProvider>
  );
}
