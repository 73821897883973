import NextLink from "next/link";
import { ReactElement, useCallback, useEffect, useRef, useState } from "react";

import { PhoneIcon } from "@chakra-ui/icons";
import { Box, Flex, Link } from "@chakra-ui/react";
import Image from "next/image";

import getScrollBarSize from "rc-util/lib/getScrollBarSize";
import { BlockRenderer } from "src/components/molecules/BlockRenderer";
import GetYourQuoteButton from "src/components/molecules/GetYourQuoteButton";
import { IHeaderFields, ISettingsFields } from "src/types/generated/contentful";
import DrawerMenu from "./Drawer";
import {
  BBBRatingImage2,
  LineSeparator,
  MenuNavWrapper,
  NavigationWrapper,
  PhoneNumber,
  SpeakText,
} from "./styles";

import SiteSearch from "src/components/molecules/SiteSearch";
import { useWindowSize } from "usehooks-ts";
import HorizontalMenu from "./HorizontalMenu";

export type MainHeaderProps = {
  data?: ISettingsFields & IHeaderFields & { "data-layoutid": string };
};

export enum MenuState {
  Closed = "closed",
  Open = "open",
}

export default function MainHeader({ data }: any): ReactElement {
  const viewportLarge = 768;
  ///  console.log("data", JSON.stringify(data));
  const layout: string = data?.layout;

  //console.log("main header", data);
  const wrapperRef = useRef(null); // used to detect clicks outside of component
  const [subMenuState, setSubMenuState] = useState<MenuState>(MenuState.Closed);
  const [activeMenus, setActiveMenus] = useState<string[]>([]); // array that captures the ids of active menus
  const [isMobile, setIsMobile] = useState<boolean>(true); // array that captures the ids of active menus
  const [drawerOpen, setDrawerOpen] = useState<boolean>(false);
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const { width, height } = useWindowSize();

  const resetMenus = (): void => {
    // close all menus and empty activeMenus array
    setActiveMenus([]);
    setSubMenuState(MenuState.Closed);
  };

  const updateActiveMenus = (state: "open" | "closed", menuId: string) => {
    if (state === "open") {
      // add menuId from activeMenus
      setActiveMenus([...activeMenus, menuId]);
    } else if (state === "closed") {
      // remove menuId from activeMenus
      setActiveMenus(activeMenus.filter((item) => item !== menuId));
    }
  };

  // Tweak rc-drawer to adjust width and overflow css of html tag instead of body tag
  const handleDrawerVisibleChange = useCallback((open: any) => {
    if (open) {
      const right =
        document.body.scrollHeight >
          (window.innerHeight || document.documentElement.clientHeight) &&
        window.innerWidth > document.body.offsetWidth
          ? getScrollBarSize(true)
          : 0;
      document.documentElement.style.overflow = "hidden";
      document.documentElement.style.width = `calc(100% - ${right}px)`;
      document.documentElement.style.touchAction = "none";
    } else {
      document.documentElement.style.overflow = "";
      document.documentElement.style.width = "";
      document.documentElement.style.touchAction = "";
    }
  }, []);

  useEffect(() => {
    if (window.innerWidth >= viewportLarge) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  }, [width]);

  const toggleSubMenu = (e: any, menuId: string, linkUrl?: string) => {
    if (!linkUrl) {
      e.preventDefault();
    }

    const nextState =
      subMenuState === MenuState.Open ? MenuState.Closed : MenuState.Open;
    //console.log("state", subMenuState, nextState, activeMenus);

    setSubMenuState(
      subMenuState === MenuState.Open ? MenuState.Closed : MenuState.Open
    );
    /*
      I haven't come up with single solution (yet) that takes care of
      opening and closing menus for both small and large screens, so for
      now I fork the logic based on viewport size.
      */
    if (!isMobile) {
      if (activeMenus.includes(menuId)) {
        // menu is already open, remove it from activeMenus to close it
        setActiveMenus([]);
      } else {
        // menu is not yet active, add it to activeMenus to open it
        setActiveMenus([menuId]);
      }
    } else {
      // remove menuId from activeMenus
      updateActiveMenus(nextState, menuId);
    }
  };

  useEffect(() => {
    const phoneNumber = data?.organization?.fields?.telephone;

    if (phoneNumber) {
      setPhoneNumber(phoneNumber);
    }
  }, [data]);

  const a11yClick = (e: any): boolean => {
    const code = e.charCode || e.keyCode;
    if (code === 32 || code === 13) {
      return true;
    }
    return false;
  };

  const doEscape = (e: any): void => {
    if (e.keyCode === 27) {
      resetMenus();
    }
  };

  const useOutsideAlerter = (ref: any) => {
    useEffect(() => {
      // Reset menu if clicked on outside of element
      const handleClickOutside = (e: any) => {
        if (ref.current && !ref.current.contains(e.target)) {
          resetMenus();
        }
      };

      // Bind the event listener to both mouse and key events
      document.addEventListener("mousedown", handleClickOutside);
      document.addEventListener("keydown", handleClickOutside);
      return () => {
        // Unbind the event listener to clean up
        document.removeEventListener("mousedown", handleClickOutside);
        document.removeEventListener("keydown", handleClickOutside);
      };
    }, [ref]);
  };

  useEffect(() => {
    document.addEventListener("keydown", doEscape, false);

    return () => {
      document.removeEventListener("keydown", doEscape, false);
    };
  });

  useOutsideAlerter(wrapperRef); // create bindings for closing menu from outside events

  const MainHeaderContentRenderer =
    data && data.content ? (
      <>
        <BlockRenderer block={data.content} layoutId={data["data-layoutid"]} />
      </>
    ) : null;

  // check settings for logo and fallback to organization logo
  const siteLogo = data?.siteLogo || data?.organization?.logo;

  return (
    <Box as="header">
      {layout && layout.indexOf("no-nav") >= 0 ? null : (
        <Box display={["none", "none", "block"]}>
          {MainHeaderContentRenderer}
        </Box>
      )}
      <NavigationWrapper>
        <Box>
          <Flex p={4} justifyContent="space-between" alignItems="center">
            <NextLink href="/" passHref legacyBehavior>
              <Link aria-label={siteLogo?.fields?.title}>
                {siteLogo ? (
                  <>
                    <Image
                      src={siteLogo?.url}
                      alt={siteLogo?.title}
                      width={siteLogo?.width}
                      height={siteLogo.height}
                    />
                  </>
                ) : null}
              </Link>
            </NextLink>

            <Box display={["none", "none", "block"]}>
              <Flex flexDirection={["row"]}>
                {layout && phoneNumber && layout.indexOf("show-phone") >= 0 ? (
                  <>
                    <SpeakText>
                      Speak With a Licensed Professional Now!
                    </SpeakText>
                    <LineSeparator />
                    <PhoneNumber href={`tel:${phoneNumber}`}>
                      {phoneNumber}
                    </PhoneNumber>
                    <BBBRatingImage2 />
                    <NextLink href={`tel:${phoneNumber}`} passHref>
                      <Link
                        display={["block", "block", "none"]}
                        variant="brand-solid-accent"
                        background="#E21866"
                      >
                        <PhoneIcon mr="2" />
                        Call Us
                      </Link>
                    </NextLink>
                  </>
                ) : (
                  <GetYourQuoteButton />
                )}
              </Flex>
            </Box>
            {/*<HamburgerIconButton
              aria-label="Menu Button"
              onClick={() => setDrawerOpen(true)}
            >
              <FontAwesomeIcon icon={faBars} />
      </HamburgerIconButton>*/}
          </Flex>
        </Box>
        {layout && layout.indexOf("no-nav") >= 0 ? null : (
          <MenuNavWrapper>
            <Flex alignItems="center">
              <HorizontalMenu
                wrapperRef={wrapperRef}
                isMobile={isMobile}
                setDrawerOpen={setDrawerOpen}
                drawerOpen={drawerOpen}
                {...{ data, toggleSubMenu, a11yClick, activeMenus }}
              />
              {/*<Button
                width={"25px"}
                height={"25px"}
                style={{
                  color: "white",
                  marginTop: "5px",
                  position: "relative",
                }}
                iconPlacement="Full"
                iconVariant="Search"
                variant="Link"
                themeName="Icon"
              >
                Search
              </Button>*/}

              <SiteSearch />
            </Flex>
            <Box display={["block", "block", "none"]}>
              {MainHeaderContentRenderer}
            </Box>
          </MenuNavWrapper>
        )}
      </NavigationWrapper>
      {layout && layout.indexOf("no-nav") >= 0 ? null : (
        <DrawerMenu
          {...{
            setDrawerOpen,
            resetMenus,
            drawerOpen,
            data,
            activeMenus,
            toggleSubMenu,
            a11yClick,
            handleDrawerVisibleChange,
          }}
        />
      )}
    </Box>
  );
}

// const MainMenuList = ({
//   data,
//   toggleSubMenu,
//   a11yClick,
//   activeMenus,
//   setDrawerOpen,
//   drawerOpen,
// }: {
//   data: any;
//   toggleSubMenu: any;
//   a11yClick: any;
//   activeMenus: any;
//   setDrawerOpen: any;
//   drawerOpen: any;
// }) => {
//   return (
//     <MainList role="menubar" aria-label="Main menu">
//       {data?.menuItems?.map((item: any, i: number) => {
//         // console.log("itemzzz", item);
//         const { title, linkUrl } = item;
//         const ariaPanelId = `menu-Mega-Menu-Panel-${i}`;

//         return (
//           <MainNavItem
//             key={i}
//             role="none"
//             id="nav-home"
//             isActive={!!activeMenus.includes(`menu-Mega-Menu-${i}`)}
//           >
//             <MainNavItemLink
//               role="menuitem"
//               id={`menuitem-Mega-Menu-${i}`}
//               href={linkUrl ?? "#"}
//               // isActive={!!activeMenus.includes(`menu-Mega-Menu-${i}`)}
//               onClick={(e) => toggleSubMenu(e, `menu-Mega-Menu-${i}`, linkUrl)}
//               onKeyDown={(e) =>
//                 a11yClick(e) && toggleSubMenu(e, `menu-Mega-Menu-${i}`, linkUrl)
//               }
//               aria-haspopup="true"
//               aria-controls={ariaPanelId}
//             >
//               {title}
//               {item.menuItems?.length > 0 ? (
//                 <i
//                   className={`fas fa-chevron-down ${
//                     activeMenus.includes(`menu-Mega-Menu-${i}`) ? "up" : ""
//                   }`}
//                 />
//               ) : null}
//             </MainNavItemLink>
//             {item.menuItems?.length > 0 ? (
//               <MegaPanel
//                 {...{
//                   i,
//                   ariaPanelId,
//                   toggleSubMenu,
//                   a11yClick,
//                   item,
//                   activeMenus,
//                   setDrawerOpen,
//                   drawerOpen,
//                 }}
//               />
//             ) : null}
//           </MainNavItem>
//         );
//       })}
//     </MainList>
//   );
// };
