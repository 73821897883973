export const SITE_NAME = "Help Center";

export const PageContentType = "page";

export const ComponentContentTypes = {
  Faq: "faq",
  NascarLogos: "nascarLogos",
  RichContent: "richContent",
  Testimonials: "testimonials",
  AnnualReviewHeader: "annualReviewHeader",
  TextTopic: "textTopic",
  Text: "component_text",
  Image: "component_image",
  Video: "component_video",
  MainHeaderContent: "mainHeaderContent",
  Hero: "mainSiteHero",
  Section: "section",
  FlexDisplay: "flexDisplay",
  ImageTopic: "imageTopic",
  VideoTopic: "videoTopic",
  SubMenuItem: "subMenuItem",
  MenuItem: "menuItem",
  Button: "button",
  FlexDisplayItem: "flexDisplayItem",
  Tabs: "tabs",
  TestimonialsSlider: "testimonialsSlider",
  FootNote: "footnote",
  TeamMember: "teamMember",
  Heading: "heading",
  SourceTopic: "sourceTopic",
  FourZeroFour: "fourZeroFour",
  MainContactForm: "mainContactForm",
  CustomContactForm: "customContactForm",
  Collection: "collection",
  FeaturedPostCard: "featuredBlogPost",
  OrgContactInfo: "orgContactInfo",
};

export const PageContentTypes = {
  HelpDeskArticle: "page_help_center_article",
  AnnualReview: "pageAnnualReview",
  LandingPage: "pageLandingPage",
};

export const fallbackImage = {
  title: "Thumbnail placeholder",
  url: "https://dummyimage.com/720x400",
};
